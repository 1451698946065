import { Component, OnInit, NgZone } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let $: any;
declare let swal: any;

@Component({
  selector: 'app-backend-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  public url_nueva_reserva:any = '';
  public id_cliente:any = null;

  constructor(
    public sanitizer: DomSanitizer,
    public ngZone: NgZone,
    public router: Router,
    public globals: Globals
    ) {
  }

  ngOnInit(): void {
    $.checkpermission();
    $('body').removeClass('bg-body-login');
    $.NuevaReserva = () => {
      $('#modal-nueva-reserva').modal('show');
    };
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        window.parent.postMessage({
          url: window.location.href,
          height: $(window).height()
        }, '*');
        window.postMessage({
          url: window.location.href,
          height: $(window).height()
        }, '*');
      }
    });
    window.parent.postMessage({
      url: window.location.href,
      height: $(window).height()
    }, '*');
    window.postMessage({
      url: window.location.href,
      height: $(window).height()
    }, '*');
    this.id_cliente = (this.globals.me && this.globals.me.id_cliente ? this.globals.me.id_cliente : null);
    if (!this.id_cliente) this.id_cliente = (this.globals.cliente && this.globals.cliente.id ? this.globals.cliente.id : null);
    if (!this.id_cliente) return;
    this.url_nueva_reserva = this.sanitizer.bypassSecurityTrustResourceUrl(
      './nueva-reserva?cliente=' + this.id_cliente + '&modaladmin=true'
    );
    if (this.globals.cliente) {
      this.globals.Api('/configuracion-public', {id_cliente: this.globals.cliente.id}).subscribe(data => {
        if (!data || data.error) return;
        this.globals.config = data;
        $.CargarConfiguracionGlobal();
      });
    }
    window.addEventListener('message', function(event) {
      if (!event.data) return;
      if (!event.data.height) event.data.height = 0;
      $('#modal-nueva-reserva .modal-body').css('height', (event.data.height + 70)+'px');
    });
  }
  Logout() {
    if ($.Logout) $.Logout();
  }
  CerrarVistaCliente() {
    this.globals.cliente = null;
    localStorage.removeItem('cliente-activo');
    localStorage.removeItem('config');
    this.globals.Api('/configuracion-public', {id_cliente: (this.globals.cliente ? this.globals.cliente.id: null)}).subscribe(data => {
      if (!data || data.error) return;
      this.globals.config = data;
      localStorage.setItem('config', JSON.stringify(this.globals.config));
      $.CargarConfiguracionGlobal();
    });
    this.ngZone.run(() => this.router.navigateByUrl('/clientes-spa')).then();
  }
  IrCliente() {
    this.globals.passData = JSON.parse(JSON.stringify(this.globals.cliente));
    this.ngZone.run(() => this.router.navigateByUrl('/clientes-spa/edit')).then();
  }

}

import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let $: any;
declare let swal: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  public email:string = '';
  public clave:string = '';
  public recuperarclave:boolean = false;

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public globals: Globals
    ) {
    let id_cliente = this.globals.GetQueryString('cliente');
    if (id_cliente && id_cliente != '') return;
    if (localStorage.getItem('token') != null) {
      this.globals.token = localStorage.getItem('token');
      this.globals.Api('/me').subscribe(data => {
        if (!data || data.error || !data.acceso) {
          return;
        }
        this.globals.me = data;
        localStorage.setItem('userdata', JSON.stringify(data));
        this.ngZone.run(() => this.router.navigateByUrl('/dashboard')).then();
      }, error => {
      });
    }
  }

  ngOnInit(): void {
    $('body').addClass('bg-body-login');
    $('#txtEmail')[0].onkeyup = event => {
      if (event.keyCode === 13) {
        event.preventDefault();
        $('#txtClave')[0].focus();
      }
    };
    $('#txtClave')[0].onkeyup = event => {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.Login();
      }
    };
  }
  Login() {
    if (
      this.email.trim() == ''
      || this.clave.trim() == ''
    ) {
      swal('Complete campos', 'Por favor, rellene todos los campos para continuar', 'error');return;
    }
    if (!this.globals.ValidateEmail(this.email.trim())) {
      swal('Email no válido', 'Por favor, introduzca un email válido.', 'error');return;
    }
    $.buttonloading('btnAcceder', 'Enviando');
    this.globals.Api('/login', {email: this.email.trim(), clave: this.clave.trim()}).subscribe(data => {
      $.buttoncancelloading('btnAcceder');
      if (!data || data.error) {
        swal('Login no válido', 'Email o contraseña incorrectos.', 'error');return;
      }
      this.globals.token = data.token;
      localStorage.setItem('token', this.globals.token);
      localStorage.removeItem('cliente-activo');
      
      this.globals.Api('/me').subscribe(data => {
        if (!data || data.error || !data.acceso) {
          this.globals.token = '';
          localStorage.removeItem('token');
          swal('Error', 'Se ha producido un error.', 'error');return;
        }
        let userdata = data;
        this.globals.me = userdata;
        localStorage.setItem('userdata', JSON.stringify(userdata));
        this.ngZone.run(() => this.router.navigateByUrl('/dashboard')).then();
      });
    }, error => {
      $.buttoncancelloading('btnAcceder');
    })
  }
  RecuperarClave() {
    if (
      this.email.trim() == ''
    ) {
      swal('Complete campos', 'Por favor, rellene todos los campos para continuar', 'error');return;
    }
    if (!this.globals.ValidateEmail(this.email.trim())) {
      swal('Email no válido', 'Por favor, introduzca un email válido.', 'error');return;
    }
    $.buttonloading('btnRecuperarClave', 'Enviando');
    this.globals.Api('/recuperar-clave-usuario', {email: this.email.trim()}).subscribe(data => {
      $.buttoncancelloading('btnRecuperarClave');
      if (!data || data.error) {
        if (data.value == 'Email no encontrado') {
          swal('Email no encontrado', 'No existe ningún usuario registrado con este email', 'error');return;
        }
        swal('Error', 'Se ha producido un error.', 'error');return;
      }
      swal('Enviado', 'Te hemos enviado un correo con tu nueva contraseña.', 'success');
      this.recuperarclave = false;
    }, error => {
      $.buttoncancelloading('btnRecuperarClave');
    });
  }
  VerClave() {
    let input = $('.input-group-clave');
    if (input.prop('visible')) {
      input.prop('visible', false);
      input.find('input').attr('type', 'password');
      input.find('.fa').addClass('fa-eye').removeClass('fa-eye-slash');
    } else {
      input.prop('visible', true);
      input.find('input').attr('type', 'text');
      input.find('.fa').addClass('fa-eye-slash').removeClass('fa-eye');
    }
  }
}

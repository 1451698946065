<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-xs-12 my-auto">
                            <div class="header-section">
                                <h1 class="middle" >Reservas</h1>
                                <button class="btn btn-xs btn-default middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>Reservas</li>
                            </ul>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center no-wrap">
                          <label class="col-form-label inline-block m-r-10">Mostrar</label>
                          <select class="form-control inline-block" (change)="Buscar()" [(ngModel)]="params.page_size" style="width:auto;">
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option>200</option>
                          </select>
                        </div>
                        <div class="col-lg-5 col-md-6 col-xs-12 my-auto text-center">
                          <div class="input-group width-100">
                            <input type="text" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
                            <div class="input-group-append cursor" (click)="Buscar()">
                              <span class="input-group-addon"><i class="fa fa-search"></i></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center">
                          <a class="btn btn-primary btn-block" (click)="Nuevo()"><i class="fa fa-plus"></i> Nueva reserva</a>
                        </div>
                    </div>
                </div>
                
                <ul class="nav nav-tabs break-padding m-t--20" data-toggle="tabs">
                  <li class="active"><a href="#tab-calendario"><i class="fa fa-calendar"></i> Vista calendario</a></li>
                  <li><a href="#tab-listado"><i class="fa fa-list"></i> Vista listado</a></li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane active" id="tab-calendario">
                    <div class="full break-padding">
                      <div class="row">
                        <div class="col-lg-4 col-lg-push-8 col-md-12 col-xs-12">
                            <div class="block full calendar-container">
                                <div class="block-title">
                                    <h2>Calendario</h2>
                                </div>
                                <div id="calendar" class="calendario-listado-reservas"></div>
                            </div>
                        </div>
                        <div class="col-lg-8 col-lg-pull-4 col-md-12 col-xs-12">
                            <div class="block full">
                                <div class="block-title">
                                  <div class="row">
                                    <div class="col-md-12 col-sm-12">
                                      <h2> 
                                          <span class="text-primary m-l-5 font-20" *ngIf="params_calendario.fecha_format">{{params_calendario.fecha_format}}</span>
                                          <span class="m-l-15 font-20" *ngIf="reservas_calendario && reservas_calendario.length" style="text-transform: none;"><span>Total:</span> {{reservas_calendario.length}}</span>
                                          <span class="m-l-30 font-20" *ngIf="reservas_calendario && reservas_calendario.length" style="text-transform: none;"><span>Nº Personas: </span>{{GetTotalPersonas()}}</span>
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                                
                                <div class="no-resultados text-center" *ngIf="!reservas_calendario || !reservas_calendario.length">
                                    <div>No existen reservas para esta fecha.</div>
                                    <a class="btn btn-primary m-t-10 m-b-30" (click)="Nuevo()"><i class="fa fa-plus"></i> Añadir reserva</a>
                                </div>
                                <div class="row row-flexbox">
                                    <ng-container *ngFor="let item of reservas_calendario">
                                      <div *ngIf="(!item.id || item.id == '') && item.tipo == 'booking'" class="col-lg-6 col-md-12 col-sm-12 col-xs-12" style="padding: 0 10px;">
                                        <a href="https://account.booking.com/sign-in?lang=es" target="_blank" class="
                                          widget 
                                          reserva-caja booking
                                          {{item.tipo_slug}} {{item.id_estado == 4 || item.id_estado == 5  || item.id_estado == 6  ? 'bg-tr-cancelada' : null}}
                                          ">
                                            <div class="widget-content text-right clearfix">
                                              <div class="pull-left icon-tipo text-left" style="width:45%;">
                                                <img src="assets/logo-booking.png" alt="">
                                                <div class="text-left font-14 m-t-5" *ngIf="item.alojamiento">{{item.alojamiento}}</div>
                                                <h5 class="text-left h5 m-t-5">Llegada: <strong>{{item.fecha_llegada_format}}</strong></h5>
                                                <h5 class="text-left h5">Salida: <strong>{{item.fecha_salida_format}}</strong></h5>
                                              </div>
                                              <h3 class="widget-heading h4"><strong>{{item.titulo}}</strong></h3>
                                              <h5 class="h5 m-t-10">{{item.tipo}}</h5>
                                              <h5 class="h5 m-t-10">Noches: <strong>{{item.noches}}</strong></h5>
                                            </div>
                                        </a>
                                      </div>
                                      <div *ngIf="(!item.id || item.id == '') && item.tipo == 'airbnb'" class="col-lg-6 col-md-12 col-sm-12 col-xs-12" style="padding: 0 10px;">
                                        <a href="https://www.airbnb.es/hosting" target="_blank" class="
                                          widget 
                                          reserva-caja booking
                                          {{item.tipo_slug}} {{item.id_estado == 4 || item.id_estado == 5  || item.id_estado == 6  ? 'bg-tr-cancelada' : null}}
                                          ">
                                            <div class="widget-content text-right clearfix">
                                              <div class="pull-left icon-tipo text-left" style="width:45%;">
                                                <img src="assets/logo-airbnb.png" alt="">
                                                <div class="text-left font-14 m-t-5" *ngIf="item.alojamiento">{{item.alojamiento}}</div>
                                                <h5 class="text-left h5 m-t-5">Llegada: <strong>{{item.fecha_llegada_format}}</strong></h5>
                                                <h5 class="text-left h5">Salida: <strong>{{item.fecha_salida_format}}</strong></h5>
                                              </div>
                                              <h3 class="widget-heading h4"><strong>{{item.titulo}}</strong></h3>
                                              <h5 class="h5 m-t-10">{{item.tipo}}</h5>
                                              <h5 class="h5 m-t-10">Noches: <strong>{{item.noches}}</strong></h5>
                                            </div>
                                        </a>
                                      </div>
                                      <div *ngIf="item.id && item.id != ''" class="col-lg-6 col-md-12 col-sm-12 col-xs-12" style="padding: 0 10px;">
                                        <a href="javascript:void(0)" class="
                                          widget 
                                          reserva-caja 
                                          {{item.tipo_slug}} {{item.id_estado == 4 || item.id_estado == 5  || item.id_estado == 6  ? 'bg-tr-cancelada' : null}}
                                          " (click)="Editar(item)">
                                            <div class="widget-content text-right clearfix">
                                              <div class="pull-left icon-tipo text-left" style="width:38%;">
                                                <img [src]="item.imagen" alt="" *ngIf="item.imagen">
                                                <div class="text-left font-14" *ngIf="item.alojamiento">{{item.alojamiento}}</div>
                                                <h5 class="text-left h5">Llegada: <strong>{{item.fecha_llegada_format}}</strong></h5>
                                                <h5 class="text-left h5">Salida: <strong>{{item.fecha_salida_format}}</strong></h5>
                                                <h5 class="text-left h5">Noches: <strong>{{item.noches}}</strong></h5>
                                                <h5 class="text-left h5" *ngIf="item.cli_tipo_pago">Pago: <strong [innerHTML]="item.cli_tipo_pago"></strong></h5>
                                                <h5 class="text-left h5" *ngIf="item.id_pago">ID Pago:<br><small class="breakword" style="font-weight:400;">{{item.id_pago}}</small></h5>
                                                <h5 class="text-left h5">Estado: <strong>{{item.estado}}</strong></h5>
                                              </div>
                                              <h3 class="widget-heading h4"><strong>{{item.cli_nombre}} {{item.cli_apellidos}}</strong></h3>
                                              <div class="h4"><strong>{{item.adultos}}</strong> adultos</div>
                                              <div class="h4" *ngIf="item.ninos"><strong>{{item.ninos}}</strong> niños <span *ngIf="item.ninos_rango_edad">{{item.ninos_rango_edad}}</span></div>
                                              <div class="text-muted breakword" *ngIf="item.cli_email"><i class="fa fa-envelope-o"></i> {{item.cli_email}}</div>
                                              <div class="text-muted" *ngIf="item.cli_telefono"><i class="fa fa-phone"></i> {{item.cli_telefono}}</div>
                                              <div class="m-t-10">
                                                  <a class="btn btn-sm btn-primary" (click)="EnviarEmail(item, $event)" *ngIf="item.cli_email && item.id_estado == 3"><i class="fa fa-envelope font-14"></i></a>
                                                  <a class="btn btn-sm btn-success m-l-10" (click)="EnviarWhatsapp(item, $event)" *ngIf="item.cli_telefono"><i class="fa fa-whatsapp font-14"></i></a>
                                              </div>
                                              <ul class="list-group list-total-reserva">
                                                <!-- <ng-container *ngIf="item.extras && item.extras.length">
                                                    <li class="list-group-item">
                                                      <div class="row">
                                                        <div class="col-xs-7 font-bold">
                                                          Total noches
                                                        </div>
                                                        <div class="col-xs-5">
                                                          <div class="text-right"><span class="font-bold num-destacado">{{item.total_noches_format}} €</span></div>
                                                        </div>
                                                      </div>
                                                    </li>
                                                    <li class="list-group-item" *ngFor="let el of item.extras">
                                                      <div class="row">
                                                        <div class="col-xs-7">
                                                          {{el.nombre}}
                                                        </div>
                                                        <div class="col-xs-5">
                                                          <div class="text-right"><span class="font-bold">+ {{el.precio_format}} €</span><br>
                                                              <small class="p-l-5">{{el.tipo_precio_texto}}</small>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </li>
                                                    <li class="list-group-item">
                                                      <div class="row">
                                                        <div class="col-xs-7 font-bold">
                                                          Extras
                                                        </div>
                                                        <div class="col-xs-5">
                                                          <div class="text-right"><span class="font-bold">{{item.total_extras_format}} €</span></div>
                                                        </div>
                                                      </div>
                                                    </li>
                                                </ng-container> -->
                                                <li class="list-group-item active">
                                                  <div class="row">
                                                    <div class="col-xs-7 font-bold">
                                                      Total reserva
                                                    </div>
                                                    <div class="col-xs-5">
                                                      <div class="text-right"><span class="font-bold num-destacado">{{item.total_format}} €</span></div>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                              <div class="clearfix"></div>
                                            </div>
                                        </a>
                                      </div>
                                    </ng-container>
                                </div>
                                <ng-container *ngIf="GetReservasCanceladas().length">
                                  <h3 class="text-center font-bold">Reservas canceladas o fallidas</h3>
                                  <hr style="border-color:#000;">
                                  <div class="row row-flexbox">
                                      <ng-container *ngFor="let item of GetReservasCanceladas()">
                                          <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12" style="padding: 0 10px;">
                                              <a href="javascript:void(0)" class="
                                                widget 
                                                reserva-caja 
                                                {{item.tipo_slug}} {{item.id_estado == 4 || item.id_estado == 5  || item.id_estado == 6  ? 'bg-tr-cancelada' : null}}
                                                " (click)="Editar(item)">
                                                  <div class="widget-content text-right clearfix">
                                                    <div class="pull-left icon-tipo text-left" style="width:38%;">
                                                      <img [src]="item.imagen" alt="" *ngIf="item.imagen">
                                                      <div class="text-left font-14" *ngIf="item.alojamiento">{{item.alojamiento}}</div>
                                                      <h5 class="text-left h5">Llegada: <strong>{{item.fecha_llegada_format}}</strong></h5>
                                                      <h5 class="text-left h5">Salida: <strong>{{item.fecha_salida_format}}</strong></h5>
                                                      <h5 class="text-left h5">Noches: <strong>{{item.noches}}</strong></h5>
                                                      <h5 class="text-left h5" *ngIf="item.cli_tipo_pago">Pago: <strong [innerHTML]="item.cli_tipo_pago"></strong></h5>
                                                      <h5 class="text-left h5" *ngIf="item.id_pago">ID Pago:<br><small class="breakword" style="font-weight:400;">{{item.id_pago}}</small></h5>
                                                      <h5 class="text-left h5">Estado: <strong>{{item.estado}}</strong></h5>
                                                    </div>
                                                    <h3 class="widget-heading h4"><strong>{{item.cli_nombre}} {{item.cli_apellidos}}</strong></h3>
                                                    <div class="h4"><strong>{{item.adultos}}</strong> adultos</div>
                                                    <div class="h4" *ngIf="item.ninos"><strong>{{item.ninos}}</strong> niños <span *ngIf="item.ninos_rango_edad">{{item.ninos_rango_edad}}</span></div>
                                                    <div class="text-muted breakword" *ngIf="item.cli_email"><i class="fa fa-envelope-o"></i> {{item.cli_email}}</div>
                                                    <div class="text-muted" *ngIf="item.cli_telefono"><i class="fa fa-phone"></i> {{item.cli_telefono}}</div>
                                                    <div class="m-t-10">
                                                        <a class="btn btn-sm btn-primary" (click)="EnviarEmail(item, $event)" *ngIf="item.cli_email && item.id_estado == 3"><i class="fa fa-envelope font-14"></i></a>
                                                        <a class="btn btn-sm btn-success m-l-10" (click)="EnviarWhatsapp(item, $event)" *ngIf="item.cli_telefono"><i class="fa fa-whatsapp font-14"></i></a>
                                                    </div>
                                                    <ul class="list-group list-total-reserva">
                                                      <!-- <ng-container *ngIf="item.extras && item.extras.length">
                                                          <li class="list-group-item">
                                                            <div class="row">
                                                              <div class="col-xs-7 font-bold">
                                                                Total noches
                                                              </div>
                                                              <div class="col-xs-5">
                                                                <div class="text-right"><span class="font-bold num-destacado">{{item.total_noches_format}} €</span></div>
                                                              </div>
                                                            </div>
                                                          </li>
                                                          <li class="list-group-item" *ngFor="let el of item.extras">
                                                            <div class="row">
                                                              <div class="col-xs-7">
                                                                {{el.nombre}}
                                                              </div>
                                                              <div class="col-xs-5">
                                                                <div class="text-right"><span class="font-bold">+ {{el.precio_format}} €</span><br>
                                                                    <small class="p-l-5">{{el.tipo_precio_texto}}</small>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </li>
                                                          <li class="list-group-item">
                                                            <div class="row">
                                                              <div class="col-xs-7 font-bold">
                                                                Extras
                                                              </div>
                                                              <div class="col-xs-5">
                                                                <div class="text-right"><span class="font-bold">{{item.total_extras_format}} €</span></div>
                                                              </div>
                                                            </div>
                                                          </li>
                                                      </ng-container> -->
                                                      <li class="list-group-item active">
                                                        <div class="row">
                                                          <div class="col-xs-7 font-bold">
                                                            Total reserva
                                                          </div>
                                                          <div class="col-xs-5">
                                                            <div class="text-right"><span class="font-bold num-destacado">{{item.total_noches_format}} €</span></div>
                                                          </div>
                                                        </div>
                                                      </li>
                                                    </ul>
                                                  </div>
                                              </a>
                                          </div>
                                      </ng-container>
                                  </div>
                                </ng-container>
                                <!-- <div class="text-center p-t-10" style="border-top: 1px solid #EEE" *ngIf="reservas && reservas.length">
                                    <a class="btn btn-primary" (click)="Nuevo()"><i class="fa fa-plus"></i> Añadir reserva</a>
                                </div> -->

                                <ng-container *ngIf="globals.me && globals.me.id_rol == '0' && globals.cliente">
                                  <a class="btn btn-default" [routerLink]="['/nueva-reserva/']" [queryParams]="{cliente: globals.cliente.id}" target="_blank">
                                    Enlace reserva (web) <i class="fa fa-external-link"></i>
                                  </a>
                                </ng-container>
                            </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="tab-pane" id="tab-listado">
                    <div class="table-responsive table-grid block full break-padding">
                        <table class="table table-striped table-bordered table-vcenter table-hover">
                            <thead>
                                <tr>
                                  <th order="referencia">Referencia</th>
                                  <th style="width:100px" order="alta">Alta</th>
                                  <th style="width:150px" order="estado">Estado</th>
                                  <th>Fechas</th>
                                  <th>Personas</th>
                                  <th>Cliente</th>
                                  <th>Total</th>
                                  <th style="width:50px">Editar</th>
                                  <th style="width:50px">Borrar</th>
                                </tr>
                            </thead>
                            <tbody>
                              <ng-container *ngFor="let item of reservas">
                                <tr class="cursor" *ngIf="(!item.id || item.id == '') && item.tipo == 'booking'">
                                  <td class="">
                                    <div class="font-bold text-primary">
                                      <img src="assets/logo-booking.png" alt="" style="height:22px;margin-right:5px;margin-bottom:5px;">
                                      Reserva Booking
                                    </div>
                                    <div class="font-bold">{{item.titulo}}</div>
                                  </td>
                                  <td></td>
                                  <td>Confirmada</td>
                                  <td>
                                      <div>Llegada: {{item.fecha_llegada_format}}</div>
                                      <div>Salida: {{item.fecha_salida_format}}</div>
                                      <div>Noches: {{item.noches}}</div>
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr class="cursor" *ngIf="(!item.id || item.id == '') && item.tipo == 'airbnb'">
                                  <td class="">
                                    <div class="font-bold text-primary">
                                      <img src="assets/logo-airbnb.png" alt="" style="height:22px;margin-right:5px;margin-bottom:5px;">
                                      Reserva Airbnb
                                    </div>
                                    <div class="font-bold">{{item.titulo}}</div>
                                  </td>
                                  <td></td>
                                  <td>Confirmada</td>
                                  <td>
                                      <div>Llegada: {{item.fecha_llegada_format}}</div>
                                      <div>Salida: {{item.fecha_salida_format}}</div>
                                      <div>Noches: {{item.noches}}</div>
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr class="cursor {{item.id_estado == 4 || item.id_estado == 5 || item.id_estado == 6 ? 'bg-tr-cancelada' : null}}"
                                  *ngIf="item.id && item.id != ''">
                                  <td class="" (click)="Editar(item)">
                                    <div class="font-bold text-primary">Reserva #{{item.referencia}}</div>
                                    <div class="text-secondary">{{item.alojamiento}}</div>
                                    <div class="text-secondary" *ngIf="globals && globals.me && globals.me.id_rol == 0" (click)="Portapapeles($event, item)">ID: {{item.id}}</div>
                                  </td>
                                  <td>{{item.alta_format}}</td>
                                  <td>
                                    <div class="font-bold">{{item.estado}}</div>
                                    <div *ngIf="item.cli_tipo_pago && item.cli_tipo_pago != '0'" class="font-11 text-primary" [innerHTML]="item.cli_tipo_pago"></div>
                                  </td>
                                  <td>
                                      <div>Llegada: {{item.fecha_llegada_format}}</div>
                                      <div>Salida: {{item.fecha_salida_format}}</div>
                                      <div>Noches: {{item.noches}}</div>
                                  </td>
                                  <td>
                                      <div *ngIf="item.adultos">Adultos: {{item.adultos}}</div>
                                      <div *ngIf="item.ninos">Ni&ntilde;os: {{item.ninos}}</div>
                                  </td>
                                  <td>
                                      <div>{{item.cli_nombre_completo}}</div>
                                      <div><a href="mailto:{{item.cli_email}}"><i class="fa fa-envelope"></i> {{item.cli_email}}</a></div>
                                      <div><a href="tel:{{item.cli_telefono}}"><i class="fa fa-phone"></i> {{item.cli_telefono}}</a></div>
                                      <div *ngIf="item.factura"><a class="btn btn-xs btn-primary" (click)="GenerarFactura(item)"><i class="fa fa-file-pdf-o"></i> Factura</a></div>
                                  </td>
                                  <td class="font-bold text-primary text-center breaktd" (click)="Editar(item)">
                                    <div class="font-20">{{globals.FloatES(item.total)}} &euro;</div>
                                  </td>
                                  <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="Editar(item)"><i class="fa fa-pencil text-white"></i></a></td>
                                  <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="Borrar(item)"><i class="fa fa-trash text-white"></i></a></td>
                                </tr>
                              </ng-container>
                            </tbody>
                        </table>
                        <div class="dataTables_paginate paging_simple_numbers m-r-10">
                          <ul class="pagination justify-content-end">
                            <li class="paginate_button page-item previous {{params.page_index <= 1 ? 'disabled' : ''}}">
                              <a (click)="Paging('prev')" class="page-link">Anterior</a>
                            </li>
                            <li class="paginate_button page-item" *ngIf="params.page_index > 1">
                              <a (click)="Paging('prev')" class="page-link">{{params.page_index-1}}</a>
                            </li>
                            <li class="paginate_button page-item active">
                              <a class="page-link cursor">{{params.page_index}}</a>
                            </li>
                            <li class="paginate_button page-item" *ngIf="params.page_index < params.pages">
                              <a (click)="Paging('next')" class="page-link">{{params.page_index+1}}</a>
                            </li>
                            <li class="paginate_button page-item next {{params.page_index >= params.pages ? 'disabled' : ''}}">
                              <a (click)="Paging('next')" class="page-link">Siguiente</a>
                            </li>
                          </ul>
                        </div>
                    </div>
                  </div>
                </div>
                
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>
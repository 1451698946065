<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row-equal-cols">
                        <div class="col-lg-8 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">{{title}}
                                  <small *ngIf="globals.me && globals.me.id_rol == 0 && data.id" class="m-l-10 text-muted">ID: {{data.id}}</small>
                                </h1>
                                <!-- <button class="btn btn-xs btn-default middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button> -->
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li *ngIf="!globals.GetQueryString('fromcal')"><a [routerLink]="['/reservas']">Reservas</a></li>
                                <li *ngIf="globals.GetQueryString('fromcal')"><a [routerLink]="['/calendario']">Reservas</a></li>
                                <li>{{title}}</li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-6 col-xs-12 col-botones text-center">
                          <a class="btn btn-danger m-r-30" (click)="Borrar()" *ngIf="data && data.id">Borrar</a>
                          <a class="btn btn-primary m-r-30" (click)="GenerarFactura()" *ngIf="data && data.id && !data.factura">
                            <i class="fa fa-file-pdf-o"></i> Generar factura
                          </a>
                          <a class="btn btn-primary m-r-30" (click)="GenerarFactura()" *ngIf="data && data.id && data.factura">
                            <i class="fa fa-file-pdf-o"></i> Factura
                          </a>
                          <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                          <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                        </div>
                    </div>
                </div>

                <div class="block full break-padding">
                    <!-- <div class="block-title">
                        <!-- <div class="block-options pull-right">
                            <a href="javascript:void(0)" class="btn btn-effect-ripple btn-default" data-toggle="tooltip" title=""
                                style="overflow: hidden; position: relative;" data-original-title="Settings"><i
                                    class="fa fa-cog"></i></a>
                        </div> -- >
                        <ul class="nav nav-tabs" data-toggle="tabs">
                            <li class="active"><a href="#tab-general">Datos generales</a></li>
                            < !-- <li><a href="#block-tabs-settings" data-toggle="tooltip" title="" data-original-title="Settings"><i class="gi gi-settings"></i></a></li> -- >
                        </ul>
                    </div> -->
                    <div class="tab-content">
                        <div class="tab-pane active" id="tab-general">

                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                            
                                    <div class="block">
                                        <div class="block-title"><h2>Datos generales</h2></div>
                                        <p *ngIf="data.id && data.id != ''">Referencia: #{{data.referencia}}</p> 
                                        <div class="form-group">
                                          <label class="text-primary">Fecha de llegada / salida *</label>
                                          <input type="text" class="form-control rangepicker" id="txtfechallegadasalida" maxlength="50" autocomplete="off"
                                            [disabled]="(!data.id_alojamiento || data.id_alojamiento == '' ? 'true' : null )">
                                        </div>
                                        <div class="clearfix"></div>
                                        <p class="text-warning" *ngIf="!data.id_alojamiento || data.id_alojamiento == ''">Seleccione un alojamiento previamente</p>
                                        <div class="form-group row">
                                            <label class="col-lg-4 col-form-label">Estado <span class="text-warning">*</span></label>
                                            <div class="col-lg-8">
                                              <select id="cmbestado" class="form-control" placeholder="Estado" [(ngModel)]="data.id_estado">
                                                <option *ngFor="let item of estados" [value]="item.id">{{item.estado}}</option>
                                              </select>
                                            </div>
                                        </div>
                                        <!-- <div class="form-group row">
                                            <label class="col-lg-4 col-form-label">Forma de pago <span class="text-warning">*</span></label>
                                            <div class="col-lg-8">
                                              <select class="form-control" placeholder="Forma de pago" [(ngModel)]="data.cli_tipo_pago">
                                                <option *ngFor="let item of formas_pago" [value]="item.slug" [innerHTML]="item.forma_pago"></option>
                                              </select>
                                            </div>
                                        </div> -->
                                        <div *ngIf="data.total">Resumen:</div>
                                        <div class="resumen-total block" *ngIf="data.extras && data.extras.length">
                                          <p class="font-16">Total noches: <span>{{data.total_noches_format}} €</span></p>
                                          <hr style="margin: 5px 0;">
                                          <ng-container *ngFor="let item of data.extras">
                                            <p class="font-14">{{item.nombre}}: <span>+ {{item.precio_format}} € <small style="font-weight:normal;color:#545454">{{item.tipo_precio_texto}}</small></span></p>
                                          </ng-container>
                                          <hr style="margin: 5px 0;">
                                          <p class="font-16">Total extras: <span>{{data.total_extras_format}} €</span></p>
                                        </div>
                                        <div class="resumen-total block" *ngIf="data.total">
                                          <p>Noches: <span>{{noches}}</span></p>
                                          <p>I.V.A.: <span>{{globals.FloatES(data.iva)}}%</span></p>
                                          <p>Base Imponible: <span>{{globals.FloatES(data.base_imponible)}}&euro;</span></p>
                                          <p class="total">Total: <span>{{globals.FloatES(data.total)}}&euro;</span></p>
                                        </div>
                                    </div>
        
                                </div>
                                <div class="col-lg-6 col-md-12">

                                    <div class="block">
                                        <div class="block-title"><h2>Cliente</h2></div>
                                        <div class="row">
                                            <div class="col-md-6 col-xs-12">
                                              <div class="form-group">
                                                <label class="text-primary">Nombre *</label>
                                                <input type="text" class="form-control" [(ngModel)]="data.cli_nombre" maxlength="80">
                                              </div>
                                            </div>
                                            <div class="col-md-6 col-xs-12">
                                              <div class="form-group">
                                                <label class="text-primary">Apellidos *</label>
                                                <input type="text" class="form-control" [(ngModel)]="data.cli_apellidos" maxlength="80">
                                              </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 col-xs-12">
                                              <div class="form-group">
                                                <label>Empresa</label>
                                                <input type="text" class="form-control" [(ngModel)]="data.cli_empresa" maxlength="80">
                                              </div>
                                            </div>
                                            <div class="col-md-6 col-xs-12">
                                              <div class="form-group">
                                                <label>NIF / CIF</label>
                                                <input type="text" class="form-control" [(ngModel)]="data.cli_nifcif" maxlength="20">
                                              </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                          <div class="col-md-8 col-xs-12">
                                            <div class="form-group">
                                              <label>Direcci&oacute;n</label>
                                              <input type="text" class="form-control" [(ngModel)]="data.cli_direccion" maxlength="200">
                                            </div>
                                          </div>
                                          <div class="col-md-4 col-xs-12">
                                            <div class="form-group">
                                              <label>C&oacute;digo postal</label>
                                              <input type="text" class="form-control" [(ngModel)]="data.cli_codpostal" maxlength="10"
                                                (change)="DatosLocalidad()">
                                            </div>
                                          </div>
                                        </div>
                                        <div class="row">
                                          <div class="col-md-6 col-xs-12">
                                            <div class="form-group">
                                              <label>Localidad</label>
                                              <input type="text" class="form-control" [(ngModel)]="data.cli_localidad" maxlength="80">
                                            </div>
                                          </div>
                                          <div class="col-md-6 col-xs-12">
                                            <div class="form-group">
                                              <label>Provincia</label>
                                              <input type="text" class="form-control" [(ngModel)]="data.cli_provincia" maxlength="80">
                                            </div>
                                          </div>
                                        </div>
                                        <div class="row">
                                          <div class="col-md-6 col-xs-12">
                                            <div class="form-group">
                                              <label>Tel&eacute;fono</label>
                                              <input type="tel" class="form-control" [(ngModel)]="data.cli_telefono" maxlength="20">
                                            </div>
                                          </div>
                                          <div class="col-md-6 col-xs-12">
                                            <div class="form-group">
                                              <label class="text-primary">Email *</label>
                                              <input type="email" class="form-control"[(ngModel)]="data.cli_email" maxlength="80">
                                            </div>
                                          </div>
                                        </div>
                                        <hr>
                                        <div class="row">
                                          <div class="col-md-12 col-xs-12">
                                            <div class="form-group">
                                              <label>Notas del cliente</label>
                                              <textarea class="form-control" rows="2" [(ngModel)]="data.cli_notas" maxlength="500"></textarea>
                                            </div>
                                          </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="block">
                                <div class="block-title">
                                    <div class="block-options pull-right">
                                        <a *ngIf="(!data.alojamientos || !data.alojamientos.length || data.alojamientos.length < 1)" (click)="AddAlojamiento()"
                                            class="btn btn-effect-ripple btn-primary" data-toggle="tooltip" style="overflow: hidden; position: relative;" data-original-title="A&ntilde;adir alojamiento">
                                            <i class="fa fa-plus"></i> A&ntilde;adir alojamiento
                                        </a>
                                    </div>
                                    <h2>Alojamientos</h2>
                                </div>
                                <table class="table table-striped table-bordered table-vcenter table-hover">
                                    <thead>
                                        <tr>
                                        <!-- <th style="width:50px;" class="text-center"><label class="csscheckbox csscheckbox-primary"><input type="checkbox"><span></span></label></th> -->
                                        <th>Alojamiento</th>
                                        <th>Adultos</th>
                                        <th>Ni&ntilde;os</th>
                                        <th>Tarifa</th>
                                        <!-- <th>Subtotal</th> -->
                                        <th style="width:50px">Borrar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of data.alojamientos" class="cursor">
                                          <td class="font-bold">{{item.alo_nombre}}</td>
                                          <td><div contenteditable (input)="EditAlojamiento(item, $event, 'adultos')">{{item.adultos}}</div></td>
                                          <td><div contenteditable (input)="EditAlojamiento(item, $event, 'ninos')">{{item.ninos}}</div></td>
                                          <td>{{item.alo_tarifa}}</td>
                                          <!-- <td class="font-bold">{{globals.FloatES(item.subtotal)}}€</td> -->
                                          <td class="text-center btntd" (click)="DelAlojamiento(item)"><a class="btn btn-danger btn-xs"><i class="fa fa-trash text-white"></i></a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="block">
                                <div class="block-title">
                                    <div class="block-options pull-right">
                                        <a  (click)="AddPago()"
                                            class="btn btn-effect-ripple btn-primary" data-toggle="tooltip" style="overflow: hidden; position: relative;" data-original-title="A&ntilde;adir pago">
                                            <i class="fa fa-plus"></i> A&ntilde;adir pago
                                        </a>
                                    </div>
                                    <h2>Pagos</h2>
                                </div>
                                <table class="table table-striped table-bordered table-vcenter table-hover">
                                    <thead>
                                        <tr>
                                        <!-- <th style="width:50px;" class="text-center"><label class="csscheckbox csscheckbox-primary"><input type="checkbox"><span></span></label></th> -->
                                        <th>Estado</th>
                                        <th>Forma pago</th>
                                        <th>Tipo</th>
                                        <th>ID pago</th>
                                        <th>Fecha</th>
                                        <th>Total</th>
                                        <th style="width:50px">Borrar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of data.pagos" class="cursor">
                                          <td class="font-bold">{{item.estado}}</td>
                                          <td [innerHTML]="item.forma_pago"></td>
                                          <td>{{item.tipo == 'manual' ? 'Manual' : 'Pasarela TPV Virtual'}}</td>
                                          <td>{{data.id_pago}}</td>
                                          <td>{{item.fecha_pago_format}}</td>
                                          <td class="font-bold">{{globals.FloatES(item.total)}}€</td>
                                          <td class="text-center btntd" (click)="DelPago(item)"><a class="btn btn-danger btn-xs"><i class="fa fa-trash text-white"></i></a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>
    
                <div class="col-botones">
                  <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                  <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                </div>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>
<div class="modal inmodal fade" id="modal-seleccionar-alojamiento" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
            <h4 class="modal-title"></h4>
        </div>
        <div class="modal-body">
          <div class="table-responsive table-grid block full break-padding">
              <!--
              Available Table Classes:
                  'table'             - basic table
                  'table-bordered'    - table with full borders
                  'table-borderless'  - table with no borders
                  'table-striped'     - striped table
                  'table-condensed'   - table with smaller top and bottom cell padding
                  'table-hover'       - rows highlighted on mouse hover
                  'table-vcenter'     - middle align content vertically
              -->
              <table class="table table-striped table-bordered table-vcenter table-hover">
                  <thead>
                      <tr>
                        <!-- <th style="width:50px;" class="text-center"><label class="csscheckbox csscheckbox-primary"><input type="checkbox"><span></span></label></th> -->
                        <th style="width:50px"></th>
                        <th order="nombre">Nombre</th>
                        <th style="width:130px" order="alta">Alta</th>
                        <th style="width:80px" order="aforo">Personas</th>
                        <th style="width:80px" order="tamano">Tama&ntilde;o</th>
                        <th style="width:50px">Seleccionar</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let item of alojamientos" class="cursor" (click)="SeleccionarAlojamiento(item)">
                        <!-- <td class="text-center"><label class="csscheckbox csscheckbox-primary"><input type="checkbox"><span></span></label></td> -->
                        <td class="text-center">
                          <img *ngIf="item.imagen" [src]="item.imagen" class="img-listado square">
                          <i *ngIf="!item.imagen || item.imagen == ''" class="fa fa-user fa-2x"></i>
                        </td>
                        <td class="font-bold text-primary">
                          <div>{{item.nombre}}</div>
                        </td>
                        <td>{{item.alta_format}}</td>
                        <td>{{item.aforo}}</td>
                        <td><div *ngIf="item.tamano">{{item.tamano}} m<sup>2</sup></div></td>
                        <td class="text-center btntd"><a class="btn btn-primary btn-xs"><i class="fa fa-check text-white"></i></a></td>
                      </tr>
                  </tbody>
              </table>
              <div class="dataTables_paginate paging_simple_numbers m-r-10">
                <ul class="pagination justify-content-end">
                  <li class="paginate_button page-item previous {{params_alo.page_index <= 1 ? 'disabled' : ''}}">
                    <a (click)="PagingAlo('prev')" class="page-link">Anterior</a>
                  </li>
                  <li class="paginate_button page-item" *ngIf="params_alo.page_index > 1">
                    <a (click)="PagingAlo('prev')" class="page-link">{{params_alo.page_index-1}}</a>
                  </li>
                  <li class="paginate_button page-item active">
                    <a class="page-link cursor">{{params_alo.page_index}}</a>
                  </li>
                  <li class="paginate_button page-item" *ngIf="params_alo.page_index < params_alo.pages">
                    <a (click)="PagingAlo('next')" class="page-link">{{params_alo.page_index+1}}</a>
                  </li>
                  <li class="paginate_button page-item next {{params_alo.page_index >= params_alo.pages ? 'disabled' : ''}}">
                    <a (click)="PagingAlo('next')" class="page-link">Siguiente</a>
                  </li>
                </ul>
              </div>
          </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
        </div>
        </div>
    </div>
</div>
<div class="modal inmodal fade" id="modal-seleccionar-tarifa" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
            <h4 class="modal-title"></h4>
        </div>
        <div class="modal-body">
          <div class="form-group row">
              <label class="col-lg-4 col-form-label">Adultos <span class="text-danger">*</span></label>
              <div class="col-lg-8">
                  <input type="number" class="form-control" placeholder="Nº Adultos" [(ngModel)]="data.adultos" maxlength="20">
              </div>
          </div>
          <div class="form-group row">
              <label class="col-lg-4 col-form-label">Niños</label>
              <div class="col-lg-8">
                  <input type="number" class="form-control" placeholder="Nº Niños" [(ngModel)]="data.ninos" maxlength="20">
              </div>
          </div>
          <!-- <div class="table-responsive table-grid">
              <table class="table table-striped table-bordered table-vcenter table-hover">
                  <thead>
                      <tr>
                        <th style="width:100px">Tarifa</th>
                        <th>Temporada</th>
                        <th>Adultos</th>
                        <th>Niños</th>
                        <th>Precio</th>
                        <th style="width:50px">Seleccionar</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let item of tarifas; let i = index" class="cursor" [attr.itemid]="item.id" (click)="SeleccionarTarifa(item)">
                        <td class="font-bold">Tarifa #{{i+1}}</td>
                        <td class="font-bold">{{item.temporada}}</td>
                        <td>{{item.adultos}}</td>
                        <td>{{item.ninos}}</td>
                        <td>{{globals.FloatES(item.precio)}}€ / noche</td>
                        <td class="text-center btntd"><a class="btn btn-primary btn-xs"><i class="fa fa-check text-white"></i></a></td>
                      </tr>
                  </tbody>
              </table>
          </div> -->
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
            <button type="button" class="btn btn-primary" (click)="AnadirAlo()">Añadir</button>
        </div>
        </div>
    </div>
</div>
<div class="modal inmodal fade" id="modal-crear-pago" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
            <h4 class="modal-title"></h4>
        </div>
        <div class="modal-body">
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Estado <span class="text-danger">*</span></label>
                <div class="col-lg-8">
                  <select id="cmbestadopago" class="form-control" placeholder="Estado" [(ngModel)]="dataitem.id_estado_pago">
                    <option *ngFor="let item of estados_pago" [value]="item.id">{{item.estado}}</option>
                  </select>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Forma de pago <span class="text-danger">*</span></label>
                <div class="col-lg-8">
                  <select id="cmbformapago" class="form-control" placeholder="Forma de pago" [(ngModel)]="dataitem.id_forma_pago">
                    <option *ngFor="let item of formas_pago" [value]="item.id">{{item.forma_pago}}</option>
                  </select>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Tipo <span class="text-danger">*</span></label>
                <div class="col-lg-8">
                  <select class="form-control" placeholder="Tipo" [(ngModel)]="dataitem.tipo">
                    <option value="manual" selected>Manual</option>
                    <option value="pasarela" selected>Pasarela TPV Virtual</option>
                  </select>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Fecha pago <span class="text-danger">*</span></label>
                <div class="col-lg-6">
                    <input type="text" class="form-control datepicker width-auto" id="txtfechapago" [(ngModel)]="dataitem.fecha_pago" maxlength="20">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Total <span class="text-danger">*</span></label>
                <div class="col-lg-8">
                    <input type="number" class="form-control width-auto" placeholder="Total" [(ngModel)]="dataitem.total" maxlength="20">
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
            <button type="button" class="btn btn-primary" id="btn-guardar-temporada" (click)="GuardarPago()">Guardar</button>
        </div>
        </div>
    </div>
</div>
import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;

@Component({
  selector: 'app-reservas',
  templateUrl: './reservas.component.html'
})
export class ReservasComponent implements OnInit {
  public reservas = [];
  public params:any = {
    page_index: 1,
    page_size: 50,
    total: 0,
    pages: 0,
    filtro: null,
    order: null,
    dir: null,
    cli_email: this.globals.GetQueryString('cliente')
  };
  public reservas_calendario = [];
  public params_calendario:any = {};
  public sesiones:any = [];

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.ComprobarLicencia();
    //Tarifa
    if (this.globals.me.id_rol != 0) {
      switch(this.globals.me.tarifa) {
        case 'spg-host-lite': {
          toastr.info('Plan limitado', 'Tu plan actual no permite reservar');
          this.ngZone.run(() => this.router.navigateByUrl('/dashboard')).then();
          return;
        } break;
      }
    }
    this.CargarGrid();
    this.globals.GridOrderJS((order, dir) => {
      this.params.order = order;
      this.params.dir = dir;
      this.params.page_index = 1;
      this.CargarGrid();
      this.appref.tick();
    });
    $('thead input:checkbox').click(function() {
      var checkedStatus   = $(this).prop('checked');
      var table           = $(this).closest('table');
      $('tbody input:checkbox', table).each(function() {
          $(this).prop('checked', checkedStatus);
      });
    });
    
    let fecha = moment(new Date()).format('YYYY-MM-DD');
    this.CargarReservasFecha(fecha);
  }

  CargarGrid(paginationload:boolean = true) {
    if (this.globals.GetData()) this.reservas = this.globals.GetData();
    this.globals.Api('/alo-reservas', this.params).subscribe(data => {
      if (!data || !data.length) {
        this.reservas = [];
        this.globals.SaveData([]);
        return;
      }
      this.globals.SaveData(data);
      this.reservas = data;
      this.appref.tick();
      if (!paginationload) return;
      this.globals.Api('/alo-alojamientos/total', this.params).subscribe(data => {
        if (!data || !data.length) return;
        this.params.total = data[0].total;
        this.params.pages = Math.ceil(this.params.total / this.params.page_size);
      });
    });
  }
  Paging(dir, table = '') {
    if (dir == 'prev') {
      this.params.page_index -= 1;
    }
    if (dir == 'next') {
      this.params.page_index += 1;
    }
    this.CargarGrid(false);
  }
  Buscar() {
    this.params.page_index = 1;
    this.CargarGrid();
  }
  Nuevo() {
    if ($.NuevaReserva) $.NuevaReserva();
    // this.globals.passData = '';
    // this.ngZone.run(() => this.router.navigateByUrl('/reservas/add')).then();
  }
  Editar(item:any) {
    this.globals.passData = JSON.parse(JSON.stringify(item));
    this.ngZone.run(() => this.router.navigateByUrl('/reservas/edit')).then();
  }
  Borrar(item:any) {
    swal({
      title: "¿Borrar reserva?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/alo-reservas-del', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.reservas.splice(this.reservas.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }
  VistaPrevia(item:any) {
    $.modalreservadata = item;
    if ($.modalreservashow) $.modalreservashow();
  }


  CargarReservasFecha(fecha:any = null) {
    if (this.globals.me.id_rol == 4) this.params_calendario.id_colaborador = this.globals.me.id;
    this.params_calendario.fecha = fecha;
    this.params_calendario.fecha_format = 'Día '+moment(fecha).format('DD/MM/YYYY');
    this.params_calendario.getbookingairbnb = true;
    if (moment().format('DD-MM-YYYY') == moment(fecha).format('DD-MM-YYYY')) this.params_calendario.fecha_format = 'Hoy';
    if (moment().add(1, 'days').format('DD-MM-YYYY') == moment(fecha).format('DD-MM-YYYY')) this.params_calendario.fecha_format = 'Mañana';
    this.globals.Api('/alo-reservas', this.params_calendario).subscribe(data => {
      if (!data || !data.length) {
        this.reservas_calendario = [];
        globalThis.reservas_calendario_original = [];
        this.CargarCalendario();
        return;
      }
      this.reservas_calendario = data.filter(el => el.id_estado <= 3);
      globalThis.reservas_calendario_original = data;
      for (let item of this.reservas_calendario) {
        item.tipo_slug = this.globals.CleanedString(item.tipo);
      }
      this.appref.tick();
      this.CargarCalendario();
    });
  }
  CargarCalendario() {
    var date = new Date();
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();
    let that = this;
    var heightCalendar = 425;
    if($(window).width() <= 1366 && $(window).width() > 1025){
      heightCalendar = 380;
    }
    if($(window).width() <= 500){
      heightCalendar = 340;
    }
    $('#calendar').fullCalendar({
        header: {
            left: 'title',
            center: '',
            right: 'today month prev,next'
        },
        defaultView: 'month',
        defaultDate: new Date(),
        locale: 'es',
        dayNamesShort: ['Dom','Lun','Mar','Mie','Jue','Vie','Sáb'],
        monthNames: ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'],
        firstDay: 1,
        allDaySlot: false,
        editable: false,
        droppable: false,
        selectable: false,
        duration: false,
        contentHeight: heightCalendar,
        viewRender: (view, element) => {
          this.CargarEventosCalendario();
          $('.calendario-listado-reservas .fc-day-top').click(function(){
            $('.calendario-listado-reservas .fc-day-top').css('background-color','unset');
            $('.calendario-listado-reservas .fc-day-top').css('color','unset');
            $(this).css('background-color','var(--color-primary)');
            $(this).css('color','white');
          });
        },
        eventRender: function(event, element) {
        },
        eventClick: function(info) {
        },
        dayClick: (date, allDay, jsEvent, view)=>{
          var tempDate = new Date(date).toDateString();
          let fecha = moment(tempDate).format('YYYY-MM-DD');
          this.CargarReservasFecha(fecha);
        }
    });
  }
  CargarEventosCalendario() {
    $('#calendar').fullCalendar('removeEvents');
    var calendar = $('#calendar').fullCalendar('getCalendar');
    var view = calendar.view;
    var start = view.start._d;
    var end = view.end._d;
    let params = {
      fecha_inicio: moment(start).format('YYYY-MM-DD'),
      fecha_fin: moment(end).format('YYYY-MM-DD'),
      getbookingairbnb: true
    };
    this.globals.Api('/alo-reservas', params).subscribe(data => {
      if (!data || !data.length) return;
      let mismaFecha = false;
      let fechaTemp = '';
      let sumPorcentajeMismaFecha = 0;
      let events = [];
      if (this.globals.GetData('eventos-calendario-reservas')) {
        events = this.globals.GetData('eventos-calendario-reservas');
        $('#calendar').fullCalendar('renderEvents', events, true);
      }
      for (let item of data) {
        let start = new Date(item.fecha_llegada);
        let end = moment(item.fecha_salida).add(1, 'day');
        let color = 'transparent';
        let cursor = 'pointer';
        let textColor = '#FFF';
        let display = 'block';
        let className = 'day-green';
        let event = {
          id: item.id,
          className: className,
          title: '',
          duration: false,
          description: `
            <div style="cursor: ${cursor}">
              <div><b><i class="fa fa-lock" style="display: ${display}; float: left; margin-right: 5px;"> <span style="color: white;"><i class="icofont-check-circled"></i></span></div>
            </div>
          `,
          start: start,
          end: end,
          data: item,
          color: color,
          //color: 'transparent',
          textColor: textColor,
          //textColor: 'transparent',
          disabled: false,
          tipo: 'documento'
        };
        events.push(event);
      }
      $('#calendar').fullCalendar('renderEvents', events, true);
    });
    
    this.appref.tick();
  }
  CalcularPersonas(item:any){
    return (item.adultos + item.ninos);
  }
  GetTotalPersonas() {

    let total:number = 0;
    if (!globalThis.reservas_calendario_original || !globalThis.reservas_calendario_original.length) return 0;
    for (let item of globalThis.reservas_calendario_original.filter(el => el.id_estado <= 3)) {
      if (item.adultos) total += item.adultos+item.ninos;
    }
    return total;
  }
  GetReservasCanceladas() {
    if (!globalThis.reservas_calendario_original || !globalThis.reservas_calendario_original.length) return [];
    return globalThis.reservas_calendario_original.filter(el => el.id_estado > 3);
  }
  EnviarEmail(item:any, event:any) {
    event.stopPropagation();
    if (!item.cli_email || item.cli_email == '') {
      toastr.info('No se ha guardado email', 'Sin email');
      return;
    }
    swal({
      title: "¿Enviar un correo de confirmación?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/alo-reservas-reenviar-email-confirmacion', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        toastr.success('Email enviado correctamente', 'Email enviado');
      });
    });
  }
  EnviarWhatsapp(item:any, event:any) {
    event.stopPropagation();
    if (!item.cli_telefono || item.cli_telefono == '') {
      toastr.info('No se ha guardado número de teléfono', 'Sin número');
      return;
    }
    let telefono = item.cli_telefono.toString().trim();
    telefono = telefono.replace(/ /g, "");
    if (telefono.indexOf('+') == -1) telefono = '+34'+telefono;
    let fecha = moment(item.alta).locale('es').format('dddd D MMMM');
    let enlace = document.createElement('a');
    let cliente = item.cli_nombre;
    enlace.href = `https://api.whatsapp.com/send?phone=${telefono}`;
    enlace.target = '_blank';
    enlace.click();
  }
  Portapapeles(event:any, item:any) {
    event.stopPropagation();
    this.globals.CopyClipboard(item.id);
    toastr.info(`ID Reserva: ${item.id}`,'Copiado al portapapeles');
  }
  GenerarFactura(item:any) {
    this.globals.Api('/alo-reservas-generar-factura-html', {id: item.id}).subscribe(data => {
      if (!data) return;
      if (data.value) {
        var mywindow = window.open('', '_blank');
        mywindow.document.write(`<title>${data.factura}.pdf</title>`);
        mywindow.document.write(data.value);
        mywindow.document.close();
        mywindow.focus();
        setTimeout(() => {
          mywindow.print();
        }, 1000);
      }
    });
  }
}

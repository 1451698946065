<div id="page-wrapper" class="page-loading">
    <!-- Preloader -->
    <!-- Preloader functionality (initialized in js/app.js) - pageLoading() -->
    <!-- Used only if page preloader enabled from inc/config (PHP version) or the class 'page-loading' is added in #page-wrapper element (HTML version) -->
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <!-- END Preloader -->

    <!-- Page Container -->
    <!-- In the PHP version you can set the following options from inc/config file -->
    <!--
        Available #page-container classes:

        'sidebar-light'                                 for a light main sidebar (You can add it along with any other class)

        'sidebar-visible-lg-mini'                       main sidebar condensed - Mini Navigation (> 991px)
        'sidebar-visible-lg-full'                       main sidebar full - Full Navigation (> 991px)

        'sidebar-alt-visible-lg'                        alternative sidebar visible by default (> 991px) (You can add it along with any other class)

        'header-fixed-top'                              has to be added only if the class 'navbar-fixed-top' was added on header.navbar
        'header-fixed-bottom'                           has to be added only if the class 'navbar-fixed-bottom' was added on header.navbar

        'fixed-width'                                   for a fixed width layout (can only be used with a static header/main sidebar layout)

        'enable-cookies'                                enables cookies for remembering active color theme when changed from the sidebar links (You can add it along with any other class)
    -->
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content" class="dashboard">
                
                <ng-container *ngIf="globals && globals.me">
                    <!-- SUPER ADMIN -->
                    <ng-container *ngIf="globals.me.id_rol == 0">
                
                        <div class="row">
                            <div class="col-sm-12 col-lg-12">
        
                                <h4>Clientes</h4>
                                <div class="row">
                                    <div class="col-xs-12 col-sm-6 col-md-3">
                                        <a [routerLink]="['/clientes-spa']" class="widget">
                                            <div class="widget-content widget-content-mini text-right clearfix">
                                                <div class="widget-icon pull-left themed-background-warning">
                                                    <i class="icofont-ui-user-group text-light-op"></i>
                                                </div>
                                                <h2 class="widget-heading h3 text-success" *ngIf="data">
                                                    <strong>&nbsp;<span data-toggle="counter" [attr.data-to]="data.total_clientes">{{data.total_clientes}}</span></strong>
                                                </h2>
                                                <span class="text-muted">Clientes</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <h4>Equipo</h4>
                                <div class="row">
                                    <div class="col-xs-12 col-sm-6 col-md-3">
                                        <a [routerLink]="['/usuarios']" class="widget">
                                            <div class="widget-content widget-content-mini text-right clearfix">
                                                <div class="widget-icon pull-left themed-background-info">
                                                    <i class="icofont-people text-light-op"></i>
                                                </div>
                                                <h2 class="widget-heading h3 text-success" *ngIf="data">
                                                    <strong>&nbsp;<span data-toggle="counter" [attr.data-to]="data.total_usuarios">{{data.total_usuarios}}</span></strong>
                                                </h2>
                                                <span class="text-muted">Usuarios</span>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-3">
                                        <a [routerLink]="['/usuarios/add']" class="widget">
                                            <div class="widget-content widget-content-mini text-right clearfix">
                                                <div class="widget-icon pull-left themed-background-info">
                                                    <i class="icofont-ui-user text-light-op"></i>
                                                </div>
                                                <h2 class="widget-heading h3">
                                                    <strong><i class="icofont-plus-circle"></i></strong>
                                                </h2>
                                                <span class="text-muted">Nuevo usuario</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                    </ng-container>

                    <!-- ADMIN -->
                    <ng-container *ngIf="globals.me.id_rol == 1 || globals.me.id_rol == 2">
                
                        <div class="row">
                            <div class="col-sm-12 col-lg-12">
        
                                <h4>Alojamientos</h4>
                                <div class="row">
                                    <div class="col-xs-12 col-sm-6 col-md-3" *ngIf="!globals.me.tarifa || globals.me.tarifa != 'spg-host-lite'">
                                        <a [routerLink]="['/reservas']" class="widget">
                                            <div class="widget-content widget-content-mini text-right clearfix">
                                                <div class="widget-icon pull-left themed-background-dark">
                                                    <i class="icofont-travelling text-light-op"></i>
                                                </div>
                                                <h2 class="widget-heading h3 text-success" *ngIf="data">
                                                    <strong>&nbsp;<span data-toggle="counter" [attr.data-to]="data.total_reservas">{{data.total_reservas}}</span></strong>
                                                </h2>
                                                <span class="text-muted">Reservas</span>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-3" *ngIf="!globals.me.tarifa || globals.me.tarifa != 'spg-host-lite'">
                                        <a class="widget cursor" (click)="NuevaReserva()">
                                            <div class="widget-content widget-content-mini text-right clearfix">
                                                <div class="widget-icon pull-left themed-background-dark">
                                                    <i class="icofont-travelling text-light-op"></i>
                                                </div>
                                                <h2 class="widget-heading h3">
                                                    <strong><i class="icofont-plus-circle"></i></strong>
                                                </h2>
                                                <span class="text-muted">Nueva reserva</span>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-3">
                                        <a [routerLink]="['/alojamientos']" class="widget">
                                            <div class="widget-content widget-content-mini text-right clearfix">
                                                <div class="widget-icon pull-left themed-background-success">
                                                    <i class="icofont-hotel text-light-op"></i>
                                                </div>
                                                <h2 class="widget-heading h3 text-success" *ngIf="data">
                                                    <strong>&nbsp;<span data-toggle="counter" [attr.data-to]="data.total_alojamientos">{{data.total_alojamientos}}</span></strong>
                                                </h2>
                                                <span class="text-muted">Alojamientos</span>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-3">
                                        <a [routerLink]="['/alojamientos/add']" class="widget">
                                            <div class="widget-content widget-content-mini text-right clearfix">
                                                <div class="widget-icon pull-left themed-background-success">
                                                    <i class="icofont-hotel text-light-op"></i>
                                                </div>
                                                <h2 class="widget-heading h3">
                                                    <strong><i class="icofont-plus-circle"></i></strong>
                                                </h2>
                                                <span class="text-muted">Nuevo alojamiento</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div class="row" *ngIf="!globals.me.tarifa || globals.me.tarifa != 'spg-host-lite'">
                                    <div class="col-xs-12 col-sm-6 col-md-3">
                                        <a [routerLink]="['/clientes']" class="widget">
                                            <div class="widget-content widget-content-mini text-right clearfix">
                                                <div class="widget-icon pull-left themed-background-warning">
                                                    <i class="icofont-ui-user-group text-light-op"></i>
                                                </div>
                                                <h2 class="widget-heading h3 text-success" *ngIf="data">
                                                    <strong>&nbsp;<span data-toggle="counter" [attr.data-to]="data.total_clientes">{{data.total_clientes}}</span></strong>
                                                </h2>
                                                <span class="text-muted">Clientes</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <h4>Equipo</h4>
                                <div class="row">
                                    <div class="col-xs-12 col-sm-6 col-md-3">
                                        <a [routerLink]="['/usuarios']" class="widget">
                                            <div class="widget-content widget-content-mini text-right clearfix">
                                                <div class="widget-icon pull-left themed-background-info">
                                                    <i class="icofont-people text-light-op"></i>
                                                </div>
                                                <h2 class="widget-heading h3 text-success" *ngIf="data">
                                                    <strong>&nbsp;<span data-toggle="counter" [attr.data-to]="data.total_usuarios">{{data.total_usuarios}}</span></strong>
                                                </h2>
                                                <span class="text-muted">Usuarios</span>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-3">
                                        <a [routerLink]="['/usuarios/add']" class="widget">
                                            <div class="widget-content widget-content-mini text-right clearfix">
                                                <div class="widget-icon pull-left themed-background-info">
                                                    <i class="icofont-ui-user text-light-op"></i>
                                                </div>
                                                <h2 class="widget-heading h3">
                                                    <strong><i class="icofont-plus-circle"></i></strong>
                                                </h2>
                                                <span class="text-muted">Nuevo usuario</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                    </ng-container>
                    <!-- CLIENTE -->
                    <ng-container *ngIf="globals.me.id_rol == 3">
                        <div class="row">
                            <div class="col-xs-12 col-sm-6 col-md-3">
                                <a [routerLink]="['/mis-reservas']" class="widget">
                                    <div class="widget-content widget-content-mini text-right clearfix">
                                        <div class="widget-icon pull-left themed-background-success">
                                            <i class="icofont-travelling text-light-op"></i>
                                        </div>
                                        <h2 class="widget-heading h3 text-success">
                                            <strong>&nbsp;<span data-toggle="counter" [attr.data-to]="data.total_reservas">{{data.total_reservas}}</span></strong>
                                        </h2>
                                        <span class="text-muted">Mis reservas</span>
                                    </div>
                                </a>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-3">
                                <a [routerLink]="['/mi-perfil']" class="widget">
                                    <div class="widget-content widget-content-mini text-right clearfix">
                                        <div class="widget-icon pull-left themed-background-info">
                                            <i class="icofont-user text-light-op"></i>
                                        </div>
                                        <h2 class="widget-heading h3 text-success">
                                            <strong>&nbsp;</strong>
                                        </h2>
                                        <span class="text-muted">Mi perfil</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>
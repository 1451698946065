import { ApplicationRef, Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let ICAL: any;
declare let Stripe: any;
declare let Litepicker: any;
declare let RealexHpp: any;

@Component({
  selector: 'app-nueva-reserva',
  templateUrl: './nueva-reserva.component.html',
  styleUrls: ['./nueva-reserva.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NuevaReservaComponent implements OnInit {
  public vista:string = (this.globals.GetQueryString('vista') ? this.globals.GetQueryString('vista') : '');
  public id_alojamiento:string = (this.globals.GetQueryString('alojamiento') ? this.globals.GetQueryString('alojamiento') : '');
  public id_cliente:string = (this.globals.GetQueryString('cliente') ? this.globals.GetQueryString('cliente') : '');
  public ids:string = (this.globals.GetQueryString('ids') ? this.globals.GetQueryString('ids') : '');
  public css:string = (this.globals.GetQueryString('css') ? this.globals.GetQueryString('css') : '');
  public admin:boolean = (this.globals.token != null && this.globals.token != '' && this.globals.GetQueryString('modaladmin') == 'true');
  public fondo:string = (this.globals.GetQueryString('fondo') ? this.globals.GetQueryString('fondo') : '');
  public tienealgunapasarela:boolean = false;
  public adultos:number = 2;
  public ninos:number = 0;
  public alojamiento:any = null;
  public alojamientos:any = [];
  public totalfechas:any = null;
  public step:string = 'fecha';
  public data:any = {};
  public dataalojamiento:any = {};
  public formas_pago:any = [];
  public fechas_bloqueadas:any = [];
  public info:string = '';
  public extras:any = [];
  public stripe:any = null;
  public stripe_card:any = null;

  constructor(
    public title: Title,
    public meta: Meta,
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    this.title.setTitle('Nueva reserva');
    if (this.vista == 'calendario') this.title.setTitle('Calendario de disponibilidad');
    this.CargarAlojamientos();
    this.CargarFormasPago();
    $(document).ready(() => {
      globalThis.getDates = function(startDate, stopDate) {
        let dateArray = [];
        let currentDate = moment(startDate.format('YYYY-MM-DD'));
        while (currentDate <= moment(stopDate.format('YYYY-MM-DD'))) {
          dateArray.push(currentDate.format('YYYY-MM-DD'));
          currentDate = currentDate.add(1, 'days');
        }
        return dateArray;
      }
      globalThis.calendar = new Litepicker({
        element: document.getElementById('calendario'),
        plugins: ['mobilefriendly'],
        inlineMode: true,
        singleMode: (this.vista == 'calendario'),
        disallowLockDaysInRange: true,
        minDate: moment(new Date()),
        lang: 'es-ES',
        tooltipText: {
          one: 'noche',
          other: 'noches'
        },
        tooltipNumber: (totalDays) => {
          globalThis.calendar.noches = totalDays - 1;
          return totalDays - 1;
        },
        lockDaysFilter: (date1, date2, pickedDates) => {
          let condition = false;
          if (date1 && date2) {
            condition = (
              this.fechas_bloqueadas.includes(date1.format('YYYY-MM-DD'))
              || this.fechas_bloqueadas.includes(date2.format('YYYY-MM-DD'))
            );
          } else {
            condition = (
              this.fechas_bloqueadas.includes(date1.format('YYYY-MM-DD'))
            );
          }
          return condition;
        }
      });
      globalThis.calendar.on('change:month', (date:any, calendarIdx:any) => {
        this.IframePostMessages();
      });
      globalThis.calendar.on('selected', (date1, date2) => {
        if (!this.vista || this.vista == '' || this.vista == 'defecto') {
          setTimeout(() => {
            this.CheckFechasSeleccionadas();
            this.CalcularTotal();
            this.IframePostMessages();
          }, 20);
        }
        if (this.vista == 'calendario') {
          this.IframePostMessages();
        }
      });
      if (this.globals.GetQueryString('height')) {
        $('.form-nueva-reserva').css('height', this.globals.GetQueryString('height')+'px');
      }
    });
    setTimeout(() => {
      if (this.css && this.css != '' && !this.admin) {
        var styleSheet = document.createElement("style");
        styleSheet.innerText = this.css;
        document.body.appendChild(styleSheet);
      }
      if (this.admin) {
        $('#global-styles').remove();
        if (document.querySelector('#global-styles')) document.querySelector('#global-styles').outerHTML = '';
        $(document).ready(function(e) {
          $('#global-styles').remove();
          if (document.querySelector('#global-styles')) document.querySelector('#global-styles').outerHTML = '';
        });
      }
      function inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
      }
      if (inIframe()) {
        var styleSheet = document.createElement("style");
        let css = `body {overflow:hidden}`;
        css = css.replace(/(\r\n|\n|\r)/gm, "");
        styleSheet.innerText = css;
        document.body.appendChild(styleSheet);
      }
      if (this.fondo == 'transparente') {
        var styleSheet = document.createElement("style");
        let css = `
          body {background-color: transparent;}
          .form-nueva-reserva {padding: 0;}
          .form-nueva-reserva h1,
          .form-nueva-reserva h5,
          .form-nueva-reserva > p,
          .form-nueva-reserva label,
          .form-nueva-reserva label a {
            color: #FFF;
          }
        `;
        css = css.replace(/(\r\n|\n|\r)/gm, "");
        styleSheet.innerText = css;
        document.body.appendChild(styleSheet);
      }
    }, 100);
    setTimeout(() => {
      if (this.admin) {
        $('#global-styles').remove();
        if (document.querySelector('#global-styles')) document.querySelector('#global-styles').outerHTML = '';
        $(document).ready(function(e) {
          $('#global-styles').remove();
          if (document.querySelector('#global-styles')) document.querySelector('#global-styles').outerHTML = '';
        });
      }
    }, 600);
    $(document).on('touchmove', function(e) {
      e.preventDefault();
    });
    if (this.id_cliente) {
      this.globals.Api('/configuracion-public', {id_cliente: this.id_cliente}).subscribe(data => {
        if (!data || data.error) return;
        this.globals.config = data;
      });
    }
    this.IframePostMessages();
    $(window).on('resize', (e) => {
      this.IframePostMessages();
    });
  }
  CargarAlojamientos() {
    this.globals.Api('/pub-alojamientos', {
      id_cliente: this.id_cliente,
      id_alojamiento: this.id_alojamiento,
      ids: this.ids,
      noches: (globalThis.calendar && globalThis.calendar.noches ? globalThis.calendar.noches : null),
      contarifas: true
    }).subscribe(data => {
      if (!data || !data.length) return;
      this.alojamientos = data;
      for (let item of this.alojamientos) item.visible = true;
      globalThis.alojamientosoriginal = JSON.parse(JSON.stringify(this.alojamientos));
      if ((!this.vista || this.vista == '' || this.vista == 'defecto')) this.SelectAlojamiento(this.alojamientos[0]);
      if (this.vista == 'calendario' && this.alojamientos.length) this.SelectAlojamientoCalendario(this.alojamientos[0]);
      // this.CargarFechasBloqueadas();
      this.IframePostMessages();
    });
  }
  CargarFormasPago() {
    if (this.admin) {
      this.formas_pago.push({
        id: 1,
        idioma: 'es',
        forma_pago: '<i class="fa fa-money"></i> Pago en mano',
        slug: 'pago-mano'
      })
      this.globals.Api('/pub-formas-pago', {id_cliente: this.id_cliente, id_alojamiento: this.id_alojamiento}).subscribe(data => {
        for (let item of data) {
          if (item.slug != 'pago-mano' && item.slug != 'transferencia') {
            this.tienealgunapasarela = true;
            break;
          }
        }
      });
      return;
    }
    this.globals.Api('/pub-formas-pago', {id_cliente: this.id_cliente, id_alojamiento: this.id_alojamiento}).subscribe(data => {
      if (!data || !data.length) return;
      this.formas_pago = data;
      if (this.formas_pago.length) this.SetFormaPago(this.formas_pago[0]);
    });
  }
  CargarFechasBloqueadas() {
    this.fechas_bloqueadas = [];
    if (this.alojamiento) {
      if (this.alojamiento.fechas_bloqueadas) {
        for (let item of this.alojamiento.fechas_bloqueadas) {
          this.fechas_bloqueadas.push(item.fecha_format);
        }
      }
    }
    globalThis.calendar.render();
    this.appref.tick();
    setTimeout(() => {
      this.CheckFechasSeleccionadas();
    }, 100);
  }
  CheckFechasSeleccionadas() {
    if (globalThis.calendar.options.startDate
      && globalThis.calendar.options.endDate) {
        let date1 = moment(globalThis.calendar.options.startDate.dateInstance);
        let date2 = moment(globalThis.calendar.options.endDate.dateInstance);
        if (this.alojamiento) {
          let noches = (this.alojamiento.num_minimo_noches ? this.alojamiento.num_minimo_noches : 2);
          if (globalThis.calendar.noches < noches
            && (!this.fechas_bloqueadas.includes( moment(date1).add(-1, 'days').format('YYYY-MM-DD') )
            || !this.fechas_bloqueadas.includes( moment(date2).add(1, 'days').format('YYYY-MM-DD') ) )
            ) {
            globalThis.calendar.clearSelection();
            globalThis.calendar.render();
            this.totalfechas = null;
            this.appref.tick();
            toastr.error('Mínimo de noches','Este alojamiento no permite menos de '+noches+(noches > 1 ? ' noches' : 'noche'));
            return;
          }
        }
        for (let item of globalThis.getDates(date1, date2)) {
          if (this.fechas_bloqueadas.includes(item)) {
            globalThis.calendar.clearSelection();
            globalThis.calendar.render();
            this.totalfechas = null;
            this.appref.tick();
            return;
          }
        }
    }
  }
  Menos(tipo:string) {
    if (tipo == 'adultos') {
      if (this.adultos > 1) this.adultos--;
    }
    if (tipo == 'ninos') {
      if (this.ninos > 0) this.ninos--;
    }
    this.CalcularTotal();
  }
  Mas(tipo:string) {
    if (tipo == 'adultos') {
      if (this.adultos < 50) this.adultos++;
    }
    if (tipo == 'ninos') {
      if (this.ninos < 50) this.ninos++;
    }
    if (this.alojamiento && this.adultos > this.alojamiento.adultos) this.adultos = this.alojamiento.adultos;
    if (this.alojamiento && this.ninos > this.alojamiento.ninos) this.ninos = this.alojamiento.ninos;
    if (this.alojamiento && (this.adultos + this.ninos) > this.alojamiento.adultos) this.ninos = this.alojamiento.adultos - this.adultos;
    if (this.ninos < 0) this.ninos = 0;
    this.CalcularTotal();
  }
  SelectAlojamiento(item) {
    for (let al of this.alojamientos) al.selected = false;
    item.selected = true;
    this.alojamiento = item;
    this.CargarFechasBloqueadas();
    let noches = (this.alojamiento.num_minimo_noches ? this.alojamiento.num_minimo_noches : 2);
    let mindias = (this.alojamiento.num_minimo_noches ? this.alojamiento.num_minimo_noches+1 : 3);
    if (mindias < 3) mindias = 3;
    if (this.adultos > this.alojamiento.adultos) this.adultos = this.alojamiento.adultos;
    if (this.ninos > this.alojamiento.ninos) this.ninos = this.alojamiento.ninos;
    if (globalThis.calendar.noches && globalThis.calendar.noches < noches) {
      globalThis.calendar.clearSelection();
      this.totalfechas = null;
      this.appref.tick();
      return;
    }
    if (this.alojamiento.extras && this.alojamiento.extras.length) {
      for (let item of this.alojamiento.extras) item.personas = 1;
    }
    $.extras = JSON.parse(JSON.stringify(this.alojamiento.extras));
    this.CalcularTotal();
  }
  CalcularTotal() {
    globalThis.temptotal = this.totalfechas;
    this.totalfechas = null;
    if (!this.id_cliente || this.id_cliente == '') return;
    if (!this.adultos && !this.ninos) return;
    if (!this.alojamiento) return;
    if (!globalThis.calendar.options.startDate || !globalThis.calendar.options.endDate) {
      this.totalfechas = null;
      this.appref.tick();
      return;
    }
    this.totalfechas = globalThis.temptotal;
    let params = {
      id_cliente: this.id_cliente,
      id_alojamiento: this.alojamiento.id,
      adultos: this.adultos,
      ninos: this.ninos,
      fecha_llegada: moment(globalThis.calendar.options.startDate.dateInstance).format('YYYY-MM-DD'),
      fecha_salida: moment(globalThis.calendar.options.endDate.dateInstance).format('YYYY-MM-DD'),
      sinanticipo: (this.admin ? true : null),
      extras: this.extras
    };
    this.globals.Api('/pub-total-fechas', params).subscribe(data => {
      if (!data || data.error) return;
      if (!globalThis.calendar.options.startDate || !globalThis.calendar.options.endDate) {
        this.totalfechas = null;
        this.appref.tick();
        return;
      }
      this.totalfechas = data;
      if (!this.totalfechas.total || this.totalfechas.total == 0 || this.totalfechas.total == '0,00') {
        this.totalfechas = null;
        this.appref.tick();
        return;
      }
      this.totalfechas.fecha_llegada = params.fecha_llegada;
      this.totalfechas.fecha_salida = params.fecha_salida;
      this.totalfechas.fecha_llegada_format = moment(globalThis.calendar.options.startDate.dateInstance).format('DD-MM-YYYY');
      this.totalfechas.fecha_salida_format = moment(globalThis.calendar.options.endDate.dateInstance).format('DD-MM-YYYY');
      this.appref.tick();
    });
  }
  SetFormaPago(item:any) {
    $('#stripe-form #card-element').html('');
    $('#stripe-form #card-errors').html('');
    $('#stripe-form').hide();
    if (!item) return;
    for (let al of this.formas_pago) al.checked = false;
    item.checked = true;
    this.data.cli_tipo_pago = item.forma_pago;
    this.data.cli_tipo_pago_slug = item.slug;

    if (item.slug == 'stripe') {
      if (!this.globals.config.pk_test || this.globals.config.pk_test == '') return;
      if (!this.globals.config.pk_live || this.globals.config.pk_live == '') return;
      let that = this;
      if (this.globals.config.st_entorno_pruebas) {
        this.stripe = Stripe(this.globals.config.pk_test);
      } else {
        this.stripe = Stripe(this.globals.config.pk_live);
      }
      var elements = this.stripe.elements();
      $('#stripe-form').show();
      var style = {
        base: {
          fontSize: '16px',
          color: 'var(--color-primary)',
        },
      };
      this.stripe_card = elements.create('card', {style: style, hidePostalCode: true});
      this.stripe_card.mount('#card-element');
    }
  }
  SetPolitica() {
    this.data.politica = $('.col-politica input[type="checkbox"]').prop('checked');
  }
  SelectAlojamientoCalendario(item) {
    for (let al of this.alojamientos) al.selected = false;
    item.selected = true;
    this.alojamiento = item;
    this.CargarFechasBloqueadas();
  }
  DatosLocalidad() {
    this.globals.Api('/localidad', {codpostal: this.data.cli_codpostal}).subscribe(data => {
      if (!data) {
        this.data.cli_localidad = '';
        this.data.cli_provincia = '';
        this.appref.tick();
        return;
      }
      this.data.cli_localidad = data.localidad;
      this.data.cli_provincia = data.provincia;
      this.appref.tick();
    });
  }
  Continuar() {
    if (!this.id_cliente || this.id_cliente == '') return;
    if (!this.adultos && !this.ninos) return;
    if (!this.alojamiento) {
      swal('Seleccione alojamiento', 'Por favor, seleccione un alojamiento para continuar', 'error');return;
    }
    if (!this.totalfechas || this.totalfechas.total == '' || this.totalfechas.total == '0,00') {
      swal('Seleccione fechas', 'Por favor, seleccione las fechas de entrada y salida para continuar', 'error');return;
    }
    this.alojamiento.extras = JSON.parse(JSON.stringify($.extras));
    this.totalfechas.extras = [];
    if (this.step == 'fecha' && this.alojamiento.extras && this.alojamiento.extras.length) {
      this.step = 'extras';
      for (let item of this.alojamiento.extras) {
        if (!item.id_tarifas) continue;
        if (item.id_tarifas.toString().indexOf(this.totalfechas.id_tarifa) == -1) {
          this.alojamiento.extras.splice(this.alojamiento.extras.findIndex(el => el.id == item.id), 1);
        }
      }
      if (!this.alojamiento.extras || !this.alojamiento.extras.length) this.step = 'resumen';
      this.IframePostMessages();
      return;
    }
    this.step = 'resumen';

    if (this.data.cli_tipo_pago_slug == 'stripe') {
      let that = this;
      if (this.globals.config.st_entorno_pruebas) {
        this.stripe = Stripe(this.globals.config.pk_test);
      } else {
        this.stripe = Stripe(this.globals.config.pk_live);
      }
      var elements = this.stripe.elements();
      $('#stripe-form').show();
      var style = {
        base: {
          fontSize: '16px',
          color: 'var(--color-primary)',
        },
      };
      this.stripe_card = elements.create('card', {style: style, hidePostalCode: true});
      this.stripe_card.mount('#card-element');
    }

    this.IframePostMessages();
  }
  Atras() {
    if (this.step == 'resumen' && this.alojamiento.extras && this.alojamiento.extras.length) {
      this.step = 'extras';
      this.IframePostMessages();
      return;
    }
    this.step = 'fecha';
    this.IframePostMessages();
  }
  Reservar() {
    if (!this.id_cliente || this.id_cliente == '') return;
    if (!this.adultos && !this.ninos) return;
    if (!this.alojamiento) return;
    if (!this.totalfechas || this.totalfechas.total == '' || this.totalfechas.total == '0,00') return;
    this.data.id_cliente = this.id_cliente;
    this.data.id_alojamiento = this.alojamiento.id;
    this.data.fecha_llegada = this.totalfechas.fecha_llegada;
    this.data.fecha_salida = this.totalfechas.fecha_salida;
    this.data.adultos = this.adultos;
    this.data.ninos = this.ninos;
    this.data.pagos = [{
      cli_tipo_pago: this.data.cli_tipo_pago,
      cli_tipo_pago_slug: this.data.cli_tipo_pago_slug
    }];
    this.data.sinanticipo = (this.admin ? true : null);

    if (
         !this.data.fecha_llegada
      || this.data.fecha_llegada == ''
      || !this.data.fecha_salida
      || this.data.fecha_salida == ''
      ) {
      swal('Seleccione fechas', 'Por favor, seleccione las fechas de entrada y salida para continuar', 'error');return;
    }
    if (
         !this.data.cli_nombre
      || !this.data.cli_apellidos
      || !this.data.cli_localidad
      || !this.data.cli_provincia
      || !this.data.cli_telefono
      || !this.data.cli_email
      || this.data.cli_nombre == ''
      || this.data.cli_apellidos == ''
      || this.data.cli_localidad == ''
      || this.data.cli_provincia == ''
      || this.data.cli_telefono == ''
      || this.data.cli_email == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if (this.globals.config) {
      if (this.globals.config.campo_dni) {
        if (
             !this.data.cli_nifcif
          || this.data.cli_nifcif == ''
        ) {
          swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
        }
      }
    }
    if (!this.data.politica && this.globals.config.url_politica_privacidad && this.globals.config.url_politica_privacidad != '') {
      swal('Política de privacidad', 'Debe marcar que ha leído y acepta la política de privacidad para continuar', 'error');return;
    }
    if (!this.globals.ValidateEmail(this.data.cli_email)) {
      swal('Email no válido', 'Por favor, introduzca un email válido.', 'error');return;
    }

    if (this.data.anticipo_importe && this.data.anticipo_importe > 0) {
      let fecha = $('#txt-fecha-expiracion').val();
      let hora = $('#txt-hora-expiracion').val();
      if (fecha && fecha != '' && hora && hora != '') {
        this.data.anticipo_fecha_expiracion = fecha+' '+hora;
      }
    }

    //Extras
    if (this.totalfechas.extras) this.data.extras = this.totalfechas.extras;


    if (this.data.cli_tipo_pago_slug == 'stripe' && this.stripe) {
      let that = this;
      $.LoadingOverlay("show", {
        background: 'rgba(255, 255, 255, 0.6)',
        imageResizeFactor: 0.5,
        text: 'Enviando datos ...'
      });
      this.stripe.createToken(this.stripe_card).then(function(result) {
        if (result.error) {
          var errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
        } else {
          that.data.precio = that.totalfechas.total;
          that.data.stripe_token = result.token.id;
          that.globals.Api('/pub-stripe-pago', that.data).subscribe(data => {
            if (!data || !data.value) {
              $.LoadingOverlay("hide", true);
              $.buttoncancelloading('btn-guardar-reserva');
              swal('Error en el pago', 'Revisa los datos de tu tarjeta', 'error');return;
            }
            setTimeout(() => {
              that.data.stripe_order = data.value;
              that.EnviarDatosReserva();
            }, 200);
          }, error => {
            $.LoadingOverlay("hide", true);
            swal('Error en el pago', 'Revisa los datos de tu tarjeta', 'error');
          });
        }
      });
      return;
    }

    this.EnviarDatosReserva();
  }
  EnviarDatosReserva() {
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.6)',
      imageResizeFactor: 0.5,
      text: 'Enviando datos ...'
    });
    this.globals.Api('/pub-reservar', this.data).subscribe(data => {
      if (!data || data.error) {
        $.LoadingOverlay("hide", true);
        toastr.error('Lo sentimos, se ha producido un error');
        return;
      }
      this.data.id = data.value;
      this.data.token_reserva = data.token;
      if (this.data.cli_tipo_pago_slug == 'addon-payments') {
        this.OpenAddonPayments();
        return;
      }
      if (data.form && data.form != '') {
        $('body').append(data.form);
        setTimeout(() => {
          if ($('#paypal-form').length) {
            $('#paypal-form').submit();
            $('#paypal-form').remove();
          }
          if ($('#redsys-form').length) {
            $('#redsys-form').submit();
            $('#redsys-form').remove();
          }
        }, 100);
      } else {
        $.LoadingOverlay("hide", true);
        swal('¡Reserva realizada con éxito!', 'Recibirá un correo a la dirección facilitada con la confirmación de la reserva.', 'success');
        if (data.token && data.token != '') {
          this.ngZone.run(() => this.router.navigateByUrl('/confirmacion-reserva?cliente='+this.id_cliente+'&token='+data.token)).then();
        }
      }
    }, error => {
      $.LoadingOverlay("hide", true);
      toastr.error('Lo sentimos, se ha producido un error');
    });
  }
  OpenAddonPayments() {
    if (this.data.cli_tipo_pago_slug == 'addon-payments') {
      $.LoadingOverlay("show", {
        background: 'rgba(255, 255, 255, 0.6)',
        imageResizeFactor: 0.5,
        text: 'Espere ...'
      });
      setTimeout(() => {
        this.data.id_cliente = this.id_cliente;
        this.data.total = this.totalfechas.total;
        this.globals.Api('/pub-addon-payments-form', this.data).subscribe((data:any) => {
          if (!data) {
            $.LoadingOverlay("hide", true);
            toastr.error('Lo sentimos, se ha producido un error');
            return;
          }
          // console.log(data.HPP_POST_RESPONSE);
          RealexHpp.setHppUrl(data.PAY_URL);
          setTimeout(() => {
            $.LoadingOverlay("hide", true);
            $('.blackdrop').show();
            RealexHpp.embedded.init("autoload", "iframe-addon-payments",
              (answer, close) => {
                // console.log('embed answer', answer);
                close();
                if (answer.AUTHCODE) {
                  $.LoadingOverlay("show", {
                    background: 'rgba(255, 255, 255, 0.6)',
                    imageResizeFactor: 0.5,
                    text: 'Espere ...'
                  });
                  this.globals.Api('/pub-addon-payments-ipn', {orderid: data.ORDER_ID}).subscribe(data => {
                    // console.log(data);
                    if (!data || !data.value) {
                      $.LoadingOverlay("hide", true);
                      toastr.error('Lo sentimos, se ha producido un error');
                      return;
                    }
                    swal('¡Reserva realizada con éxito!', 'Recibirá un correo a la dirección facilitada con la confirmación de la reserva.', 'success');
                    this.ngZone.run(() => this.router.navigateByUrl('/confirmacion-reserva?cliente='+this.id_cliente+'&token='+this.data.token_reserva)).then();
                  });
                } else {
                  toastr.error('Lo sentimos, se ha producido un error');
                  $('.blackdrop').hide();
                  // console.log(answer.MESSAGE);
                  this.globals.Api('/pub-addon-payments-fallida', {id: this.data.id}).subscribe();
                  $('.iframe-container').html(`<iframe id="iframe-addon-payments" frameborder="0" style="display:none"></iframe>`);
                }
              }, data, { onResize: function(data) {
                $('#iframe-addon-payments').css(data)
              }});
            window.addEventListener('message', response => {
              // console.log(response.data);
            }, false);
          }, 100);
          $('body').addClass('loaded');
        });
      }, 200);
    }
  }
  SetExtra(item:any) {
    if (!item || !item.precio) return;
    let el = this.extras.find(el => el.id == item.id);
    item.checked = !item.checked;
    if (item.checked) {
      if (!el) this.extras.push(item);
    } else {
      this.extras.splice(this.extras.findIndex(e => e == el), 1);
    }
    this.CalcularTotal();
    this.IframePostMessages();
  }
  VerExtraDescripcion(item:any) {
    if (!item || !item.nombre || !item.descripcion) return;
    this.info = item.descripcion;
    $('#modal-info .modal-title').html(item.nombre);
    $('#modal-info').modal('show');
  }
  MenosExtra(item:any) {
    if (item.personas <= 1) return;
    item.personas -= 1;
    this.CalcularTotal();
  }
  MasExtra(item:any) {
    item.personas += 1;
    if (this.alojamiento && item.personas > (this.adultos + this.ninos)) item.personas = (this.adultos + this.ninos);
    this.CalcularTotal();
  }
  IframePostMessages() {
    setTimeout(() => {
      window.parent.postMessage({
        url: window.location.href,
        height: $('.form-nueva-reserva').height()
      }, '*');
      window.postMessage({
        url: window.location.href,
        height: $('.form-nueva-reserva').height()
      }, '*');
    }, 100);
  }
  VerDatosAlojamiento(item:any, event:any) {
    if (!item) return;
    event.stopPropagation();
    this.dataalojamiento = item;
    $('#modal-alojamiento .modal-title').html(item.nombre);
    $('#modal-alojamiento').modal('show');
  }
}

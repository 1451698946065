import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from './classes/globals.class';
import { Title, Meta } from '@angular/platform-browser';

declare let $: any;
declare let swal: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(
    public title: Title,
    public meta: Meta,
    public ngZone: NgZone,
    public router: Router,
    public globals: Globals
  ) {
    $.buttonloading = (id, text = '', clase = 'text-light') => {
      let selector = `#${id}`;
      if (id.indexOf('.') != -1) selector = `${id}`;
      $(selector)[0].originaltext = $(selector)[0].innerHTML;
      $(selector).html(`
        ${text}
        <div class="spinner-grow spinner-grow-sm middle ${clase}" role="status"></div>
      `);
      $(selector).attr('disabled', true);
      $(selector).css('pointer-events', 'none');
      $(selector).css('cursor', 'not-allowed');
    };
    $.buttoncancelloading = id => {
      let selector = `#${id}`;
      if (id.indexOf('.') != -1) selector = `${id}`;
      $(selector).html($(selector)[0].originaltext);
      $(selector).removeAttr('disabled');
      $(selector).css('pointer-events', 'all');
      $(selector).css('cursor', 'pointer');
    };
    $.disableinputnumberwheel = () => {
      $(document).on("wheel", "input[type=number]", function (e) {
        $(this).blur();
      });
    };
    $.fn.datepicker.dates['es'] = {
      days: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
      daysShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
      daysMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
      months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
      monthsShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
      today: "Hoy",
      monthsTitle: "Meses",
      clear: "Borrar",
      weekStart: 1,
      format: "dd/mm/yyyy"
    };
    $.checkrolredirect = (id_rol:any) => {
      if (this.globals.me.id_rol == id_rol) {
        this.ngZone.run(() => this.router.navigateByUrl('/dashboard')).then();
      }
    };
    $.checkpermission = () => {
      if (!localStorage.getItem('token')) {
        this.ngZone.run(() => this.router.navigateByUrl('/admincontrol')).then();
      }
    };
    
    //CERRAR SESIÓN
    $.Logout = () => {
      swal({
        title: "¿Deseas cerrar sesión?",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-warning",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
        closeOnConfirm: true
      },() => {
        this.globals.Api('/del-webtoken').subscribe(data => {}, error => {});
        localStorage.removeItem('token');
        localStorage.removeItem('userdata');
        localStorage.clear();
        this.globals.token = '';
        this.globals.me = {};
        this.ngZone.run(() => this.router.navigateByUrl('/login')).then();
      });
    };
    //COMPROBAR LICENCIA
    $.ComprobarLicencia = () => {
      this.globals.Api('/me').subscribe(data => {
        if (!data || data.error) return;
        this.globals.me = data;
        if (this.globals.me.licencia && this.globals.me.licencia.error) {
          localStorage.removeItem('token');
          localStorage.removeItem('userdata');
          localStorage.clear();
          setTimeout(() => {
            $.LoadingOverlay("show", {
              background: 'rgba(0, 0, 0, 0.6)',
              imageResizeFactor: 0.5,
              progress: false,
              custom: '<h3>¡Licencia expirada!</h3><a href="https://pecesgordos.es" target="_blank">Por favor, contacte con <b>Peces Gordos Estudio</b> para renovar la licencia.</a>'
            });
            $('.loadingoverlay').css('justify-content', 'center');
            $('.loadingoverlay svg').hide();
            $('.loadingoverlay svg').css('display', 'none');
            $('.loadingoverlay_element').css('display', 'block');
            $('.loadingoverlay_element h3').css('text-shadow', '0 0 3px black');
            $('.loadingoverlay_element h3').css('color', 'var(--color-primary)');
            $('.loadingoverlay_element h3').css('font-size', '24px');
            $('.loadingoverlay_element a').css('color', '#FFF');
            $('.loadingoverlay_element a').css('font-size', '18px');
            $('.loadingoverlay_element a').css('text-shadow', '0 0 3px black');
            $('.loadingoverlay_element').css('width', '300px');
          }, 200);
        }
      });
    };
    
    //CARGAR CONFIGURACION GENERAL
    let id_cliente = this.globals.GetQueryString('cliente');
    if (id_cliente && id_cliente != '') {
      this.globals.Api('/configuracion-public', {id_cliente: id_cliente}).subscribe(data => {
        if (!data || data.error) return;
        this.globals.config = data;
        this.CargarConfiguracionGlobal();
        localStorage.setItem('config', JSON.stringify(this.globals.config));
      });
      if (localStorage.getItem('config')) {
        this.globals.config = JSON.parse(localStorage.getItem('config'));
        this.CargarConfiguracionGlobal();
      }
    } else {
      if (localStorage.getItem('config') == null) {
        $.LoadingOverlay("show", {
          background: 'rgba(255, 255, 255, 0.6)',
          imageResizeFactor: 0.5
        });
        this.globals.Api('/configuracion-public').subscribe(data => {
          $.LoadingOverlay("hide");
          if (!data || data.error) return;
          this.globals.config = data;
          localStorage.setItem('config', JSON.stringify(this.globals.config));
          this.CargarConfiguracionGlobal();
        }, error => {
          $.LoadingOverlay("hide");
        });
      } else {
        this.globals.config = JSON.parse(localStorage.getItem('config'));
        this.CargarConfiguracionGlobal();
        this.globals.Api('/configuracion-public').subscribe(data => {
          if (!data || data.error) return;
          this.globals.config = data;
          localStorage.setItem('config', JSON.stringify(this.globals.config));
          this.CargarConfiguracionGlobal();
        });
      }
    }
    $.CargarConfiguracionGlobal = () => {
      this.CargarConfiguracionGlobal();
    };

    //VISTA CLIENTE
    if (this.globals.GetData('cliente-activo')) {
      this.globals.cliente = this.globals.GetData('cliente-activo');
    }

    if (localStorage.getItem('token') != null) {
      this.globals.token = localStorage.getItem('token');
      if (localStorage.getItem('userdata') != null) this.globals.me = JSON.parse(localStorage.getItem('userdata'));
      this.globals.Api('/me').subscribe(data => {
        if (!data || data.error || !data.acceso) {
          if (
               window.location.pathname.indexOf('/nueva-reserva') != -1
            || window.location.pathname.indexOf('/confirmacion-reserva') != -1
            || window.location.pathname.indexOf('/reserva-cancelada') != -1
            || window.location.pathname.indexOf('/reserva-error') != -1
            || window.location.pathname.indexOf('/cancelar-reserva') != -1
            || window.location.pathname.indexOf('/pago-anticipo') != -1
          ) return;
          this.ngZone.run(() => this.router.navigateByUrl('/login')).then();
          return;
        }
        let userdata = data;
        this.globals.me = userdata;
        if ($.MeDataCompleted) $.MeDataCompleted();
        localStorage.setItem('userdata', JSON.stringify(userdata));
        if (this.router.url == '/login' || this.router.url == '/') {
          this.ngZone.run(() => this.router.navigateByUrl('/dashboard')).then();
        }
      }, error => {
        if (
             window.location.pathname.indexOf('/nueva-reserva') != -1
          || window.location.pathname.indexOf('/confirmacion-reserva') != -1
          || window.location.pathname.indexOf('/reserva-cancelada') != -1
          || window.location.pathname.indexOf('/reserva-error') != -1
          || window.location.pathname.indexOf('/cancelar-reserva') != -1
          || window.location.pathname.indexOf('/pago-anticipo') != -1
        ) return;
        this.ngZone.run(() => this.router.navigateByUrl('/login')).then();
      });
    } else {
      if (
           window.location.pathname.indexOf('/nueva-reserva') != -1
        || window.location.pathname.indexOf('/confirmacion-reserva') != -1
        || window.location.pathname.indexOf('/reserva-cancelada') != -1
        || window.location.pathname.indexOf('/reserva-error') != -1
        || window.location.pathname.indexOf('/cancelar-reserva') != -1
        || window.location.pathname.indexOf('/pago-anticipo') != -1
      ) return;
      this.ngZone.run(() => this.router.navigateByUrl('/login')).then();
    }
  }
  CargarConfiguracionGlobal() {
    if (!this.globals.config) return;
    if (document.head.querySelector('#global-styles')) {
      document.head.querySelector('#global-styles').remove();
    }
    if (this.globals.config.customcss) {
      const style = document.createElement('style');
      style.id = 'global-styles';
      style.textContent = this.globals.config.customcss;
      document.head.append(style);
    }
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AlojamientosComponent } from './backend/alojamientos/alojamientos.component';
import { EditAlojamientoComponent } from './backend/alojamientos/edit-alojamiento/edit-alojamiento.component';
import { CalendarioComponent } from './backend/calendario/calendario.component';
import { ConfigAppComponent } from './backend/config-app/config-app.component';
import { ConfiguracionComponent } from './backend/configuracion/configuracion.component';
import { DashboardComponent } from './backend/dashboard/dashboard.component';
import { LoginComponent } from './backend/login/login.component';
import { MiPerfilComponent } from './backend/mi-perfil/mi-perfil.component';
import { EditReservaComponent } from './backend/reservas/edit-reserva/edit-reserva.component';
import { ReservasComponent } from './backend/reservas/reservas.component';
import { ClientesSpaComponent } from './backend/superadmin/clientes-spa/clientes-spa.component';
import { EditClienteSpaComponent } from './backend/superadmin/clientes-spa/edit-cliente-spa/edit-cliente-spa.component';
import { TemporadasComponent } from './backend/temporadas/temporadas.component';
import { EditUsuarioComponent } from './backend/usuarios/edit-usuario/edit-usuario.component';
import { UsuariosComponent } from './backend/usuarios/usuarios.component';
import { CancelarReservaComponent } from './frontend/cancelar-reserva/cancelar-reserva.component';
import { ConfirmacionReservaComponent } from './frontend/confirmacion-reserva/confirmacion-reserva.component';
import { NuevaReservaComponent } from './frontend/nueva-reserva/nueva-reserva.component';
import { PagoAnticipoComponent } from './frontend/pago-anticipo/pago-anticipo.component';
import { ReservaCanceladaComponent } from './frontend/reserva-cancelada/reserva-cancelada.component';
import { ReservaErrorComponent } from './frontend/reserva-error/reserva-error.component';

const routes: Routes = [
  //BACKEND
  {path: '', component: LoginComponent},
  {path: 'login', component: LoginComponent},
  {path: 'admincontrol', component: LoginComponent},
  {path: 'dashboard', component: DashboardComponent},
  {path: 'config-app', component: ConfigAppComponent},
  {path: 'configuracion', component: ConfiguracionComponent},
  {path: 'mi-perfil', component: MiPerfilComponent},
  {path: 'usuarios', component: UsuariosComponent},
  {path: 'usuarios/add', component: EditUsuarioComponent},
  {path: 'usuarios/edit', component: EditUsuarioComponent},
  {path: 'clientes', component: UsuariosComponent},
  {path: 'clientes/add', component: EditUsuarioComponent},
  {path: 'clientes/edit', component: EditUsuarioComponent},
  {path: 'temporadas', component: TemporadasComponent},
  {path: 'alojamientos', component: AlojamientosComponent},
  {path: 'alojamientos/add', component: EditAlojamientoComponent},
  {path: 'alojamientos/edit', component: EditAlojamientoComponent},
  {path: 'reservas', component: ReservasComponent},
  {path: 'mis-reservas', component: ReservasComponent},
  {path: 'reservas/add', component: EditReservaComponent},
  {path: 'reservas/edit', component: EditReservaComponent},
  {path: 'clientes-spa', component: ClientesSpaComponent},
  {path: 'clientes-spa/add', component: EditClienteSpaComponent},
  {path: 'clientes-spa/edit', component: EditClienteSpaComponent},
  {path: 'calendario', component: CalendarioComponent},
  //FRONEND
  {path: 'nueva-reserva', component: NuevaReservaComponent},
  {path: 'confirmacion-reserva', component: ConfirmacionReservaComponent},
  {path: 'reserva-cancelada', component: ReservaCanceladaComponent},
  {path: 'reserva-error', component: ReservaErrorComponent},
  {path: 'cancelar-reserva', component: CancelarReservaComponent},
  {path: 'pago-anticipo', component: PagoAnticipoComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;

@Component({
  selector: 'app-clientes-spa',
  templateUrl: './clientes-spa.component.html'
})
export class ClientesSpaComponent implements OnInit {
  public title:string = 'Clientes';
  public data = [];
  public params:any = {
    page_index: 1,
    page_size: 50,
    total: 0,
    pages: 0,
    filtro: null,
    order: null,
    dir: null,
  };
  public tarifas = [];

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(3); //No clientes
    this.CargarGrid();
    this.globals.GridOrderJS((order, dir) => {
      this.params.order = order;
      this.params.dir = dir;
      this.params.page_index = 1;
      this.CargarGrid();
    });
    $('thead input:checkbox').click(function() {
      var checkedStatus   = $(this).prop('checked');
      var table           = $(this).closest('table');
      $('tbody input:checkbox', table).each(function() {
          $(this).prop('checked', checkedStatus);
      });
    });
    this.globals.Api('/tarifas').subscribe(data => {
      if (!data || data.length == 0) return;
      this.tarifas = data;
    });
  }

  CargarGrid(paginationload:boolean = true) {
    this.params.id_rol = '0,1,2';
    if (this.globals.GetData()) this.data = this.globals.GetData();
    this.globals.Api('/clientes', this.params).subscribe(data => {
      if (!data || !data.length) {
        this.data = [];
        this.globals.SaveData([]);
        return;
      }
      this.globals.SaveData(data);
      this.data = data;
      this.appref.tick();
      if (!paginationload) return;
      this.globals.Api('/clientes/total', this.params).subscribe(data => {
        if (!data || !data.length) return;
        this.params.total = data[0].total;
        this.params.pages = Math.ceil(this.params.total / this.params.page_size);
      });
    });
  }
  Paging(dir, table = '') {
    if (dir == 'prev') {
      this.params.page_index -= 1;
    }
    if (dir == 'next') {
      this.params.page_index += 1;
    }
    this.CargarGrid(false);
  }
  Buscar() {
    this.params.page_index = 1;
    this.CargarGrid();
  }
  Filtrar(tipo:string = 'todo', value:string = '') {
    switch(tipo) {
      case 'todo': {
        this.params.id_tarifa = null;
        this.Buscar();
      } break;
      case 'tarifa': {
        this.params.id_tarifa = value;
        this.Buscar();
      } break;
    };
  }
  FiltrarFecha(filtro:string) {
    this.params.tipofiltrofecha = filtro;
    let fecha = moment($("#txtFiltroFecha").datepicker('getDate'), 'YYYY-MM-DD');
    switch(filtro) {
      case 'alta': {
        this.params.fechaalta = fecha;
        this.params.fechapagado = null;
        this.params.fechacaducidad = null;
        this.Buscar();
      } break;
    };
  }
  Nuevo() {
    this.globals.passData = '';
    this.ngZone.run(() => this.router.navigateByUrl('/clientes-spa/add')).then();
  }
  Editar(item:any) {
    this.globals.passData = JSON.parse(JSON.stringify(item));
    this.ngZone.run(() => this.router.navigateByUrl('/clientes-spa/edit')).then();
  }
  Borrar(item:any) {
    swal({
      title: "¿Borrar clientes?",
      text: "¡Se borrarán todos sus datos y no se podrá recuperar!",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/clientes-del', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.data.splice(this.data.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }
  VistaCliente(item:any) {
    this.globals.cliente = JSON.parse(JSON.stringify(item));
    this.globals.SaveData(this.globals.cliente, 'cliente-activo');
    localStorage.removeItem('config');
    this.globals.Api('/configuracion-public', {id_cliente: (this.globals.cliente ? this.globals.cliente.id: null)}).subscribe(data => {
      if (!data || data.error) return;
      this.globals.config = data;
      localStorage.setItem('config', JSON.stringify(this.globals.config));
      $.CargarConfiguracionGlobal();
    });
    this.ngZone.run(() => this.router.navigateByUrl('/reservas')).then();
  }
  Portapapeles(event:any, item:any) {
    event.stopPropagation();
    this.globals.CopyClipboard(item.id);
    toastr.info(`ID Cliente: ${item.id}`,'Copiado al portapapeles');
  }

}

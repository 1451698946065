<div class="response-reserva form-nueva-reserva">
    <h1>Cancelar reserva</h1>
    <ng-container *ngIf="data && data.id">
        <p class="font-18" *ngIf="data.puede_cancelar">Puede cancelar hasta <strong>{{data.dias_cancelacion}} días</strong> de la fecha de llegada</p>
        <p class="font-18 text-danger" *ngIf="!data.puede_cancelar">Esta reserva no permite cancelación actualmente</p>
        <p>A continuación te mostramos los detalles de tu reserva:</p>
        <div class="row row-small datos-reserva">
          <div class="col-xs-6">
            <ul class="list-group">
                <li class="list-group-item active">Tus datos</li>
                <li class="list-group-item datos">
                  <div>{{data.cli_nombre}} {{data.cli_apellidos}}</div>
                  <div *ngIf="data.cli_empresa">{{data.cli_empresa}}<span *ngIf="data.cli_nifcif">, {{data.cli_nifcif}}</span></div>
                  <div>{{data.cli_direccion}}<span *ngIf="data.cli_codpostal">, {{data.cli_codpostal}}</span></div>
                  <div>{{data.cli_localidad}}<span *ngIf="data.cli_pais">, {{data.cli_pais}}</span></div>
                  <div *ngIf="data.cli_email"><i class="fa fa-envelope-o"></i> {{data.cli_email}}</div>
                  <div *ngIf="data.cli_telefono"><i class="fa fa-phone"></i> {{data.cli_telefono}}</div>
                  <div *ngIf="data.cli_notas"><br>{{data.cli_notas}}</div>
                </li>
            </ul>
            <ul class="list-group" *ngIf="data.porcent_anticipo && data.total_anticipo">
                <li class="list-group-item active">Modalidad de pago</li>
                <li class="list-group-item datos">
                  <div class="dato-destacado" [innerHTML]="data.cli_tipo_pago"></div>
                  <div>Importe anticipo: ({{data.porcent_anticipo}}%): <strong>{{data.total_anticipo}} €</strong></div>
                  <div class="m-t-10">El importe restante se pagará a la llegada al alojamiento ({{data.restante_anticipo}} €)</div>
                </li>
            </ul>
          </div>
          <div class="col-xs-6">
            <ul class="list-group">
                <li class="list-group-item active">Datos de la reserva</li>
                <li class="list-group-item datos">
                  <div class="dato-destacado">#{{data.referencia}}</div>
                  <div class="dato-destacado">{{data.alojamiento}}</div>
                  <div>Precio: {{data.tarifa}}</div>
                  <div>Llegada: {{data.fecha_llegada_format}}</div>
                  <div>Salida: {{data.fecha_salida_format}}</div>
                  <div>Adultos: {{data.adultos}}</div>
                  <div>Niños: {{data.ninos}}</div>
                </li>
                <ng-container *ngIf="data.extras && data.extras.length">
                  <li class="list-group-item datos">
                    <div class="row">
                      <div class="col-xs-7 font-bold">
                        Total noches
                      </div>
                      <div class="col-xs-5">
                        <div class="text-right"><span class="font-bold">{{data.total_noches_format}} €</span></div>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item datos" *ngFor="let item of data.extras">
                    <div class="row">
                      <div class="col-xs-7">
                        {{item.nombre}}
                      </div>
                      <div class="col-xs-5">
                        <div class="text-right"><span class="font-bold">+ {{item.subtotal_format}} €</span><br>
                            <small class="p-l-5">{{item.tipo_precio_texto}}</small>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item datos">
                    <div class="row">
                      <div class="col-xs-7 font-bold">
                        Extras
                      </div>
                      <div class="col-xs-5">
                        <div class="text-right"><span class="font-bold">{{data.total_extras_format}} €</span></div>
                      </div>
                    </div>
                  </li>
                </ng-container>
                <li class="list-group-item active font-bold">
                  Total reserva
                  <div class="pull-right font-20">{{data.total_format}} €</div>
                </li>
            </ul>
            <div class="clearfix"></div>
          </div>
        </div>
        <a (click)="CancelarReserva()" class="btn btn-lg btn-danger m-t-20" *ngIf="data.puede_cancelar"><i class="fa fa-times"></i> Cancelar esta reserva</a>
    </ng-container>
    <p *ngIf="error" class="font-18"><i class="fa fa-exclamation-triangle"></i> ¡Lo sentimos! se ha producido un error</p>
</div>
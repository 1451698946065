import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let tinymce: any;

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html'
})
export class ConfiguracionComponent implements OnInit {
  public temporadas = [];
  public data:any = {};

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(3); //No clientes
    if (this.globals.GetData()) this.data = this.globals.GetData();
    this.globals.Api('/configuracion').subscribe(data => {
      if (!data) return;
      this.data = data;
      this.globals.SaveData(data);
    });
    tinymce.remove();
    setTimeout(() => {
      let move = function (arr, from, to) {
        arr.splice(to, 0, arr.splice(from, 1)[0]);
      };
      let that = this;
      tinymce.init({
        selector:'.text-editor',
        height: 300,
        plugins: 'print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap tinycomments mentions quickbars linkchecker emoticons advtable code',
        toolbar: 'undo redo | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment | code',
        language: 'es',
        relative_urls: false,
        remove_script_host : false,
        convert_urls : false,
        paste_data_images: true,
        images_upload_handler: function (blobInfo, success, failure) {
          var image_size = blobInfo.blob().size / 1000;  // image size in kbytes
          var max_size   = 1024;                         // max size in kbytes
          if ( image_size  > max_size ) {
            alert('La imagen es demasiado grande ( '+ image_size  + '), Máximo: ' + max_size + ' kb');     
            failure('La imagen es demasiado grande ( '+ image_size  + '), Máximo: ' + max_size + ' kb', { remove: true });
            return;      
          } else {
            that.globals.Api('/pub-upload', {img: blobInfo.base64()}).subscribe(data => {
              if (!data || !data.url || data.url == '') {
                failure('Error al subir la imagen', { remove: true });
                return;
              }
              success(data.url);
            }, error => {
              failure('Error al subir la imagen', { remove: true });
            });
          }
        },
        content_style: `body {font-size:11px}`
      });
    }, 100);
  }

  Guardar() {
    this.data.texto_pie_factura = tinymce.EditorManager.get('txttextopiefactura').getContent();
    $.buttonloading('.btn-guardar', 'Enviando');
    this.globals.Api('/configuracion-edit', this.data).subscribe(data => {
      $.buttoncancelloading('.btn-guardar');
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      swal('Guardado', 'Datos guardados correctamente', 'success');
    }, error => {
      $.buttoncancelloading('.btn-guardar');
    });
  }

}

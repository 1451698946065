<div class="response-reserva">
    <h1 style="font-weight:bold;">Petición de pago</h1>
    <p class="font-18">Para confirmar tu reserva es necesario el pago de un anticipo de <b class="font-20">{{data.anticipo_importe_format}} €</b>.<br>Selecciona una forma de pago:</p>
    
    <div class="row row-small datos-reserva" style="margin-bottom:10px !important;">
        <div class="col-xs-12">
            
            <ul class="list-group">
                <li class="list-group-item active">Formas de pago</li>
                <li class="list-group-item">
                    <a class="btn btn-default btn-{{item.slug}} m-r-10" (click)="SetFormaPago(item)" *ngFor="let item of formas_pago">
                        <label class="csscheckbox csscheckbox-warning"><input type="checkbox" [checked]="item.checked"><span></span></label>
                        <span class="texto" [innerHTML]="item.forma_pago"></span>
                    </a>
                </li>
                <li class="list-group-item" *ngIf="data.anticipo_fecha_expiracion">
                    Dispone hasta el <strong>{{data.anticipo_fecha_expiracion}}</strong><span *ngIf="data.anticipo_hora_expiracion"> a las <strong>{{data.anticipo_hora_expiracion}}h</strong></span>
                    para realizar el pago
                </li>
            </ul>
        </div>
    </div>

    <p>A continuación te mostramos los detalles de tu reserva:</p>
    <div class="row row-small datos-reserva" *ngIf="data && data.id">
      <div class="col-xs-6">
        <ul class="list-group">
            <li class="list-group-item active">Tus datos</li>
            <li class="list-group-item datos">
              <div>{{data.cli_nombre}} {{data.cli_apellidos}}</div>
              <div *ngIf="data.cli_empresa">{{data.cli_empresa}}<span *ngIf="data.cli_nifcif">, {{data.cli_nifcif}}</span></div>
              <div>{{data.cli_direccion}}<span *ngIf="data.cli_codpostal">, {{data.cli_codpostal}}</span></div>
              <div>{{data.cli_localidad}}<span *ngIf="data.cli_pais">, {{data.cli_pais}}</span></div>
              <div *ngIf="data.cli_email"><i class="fa fa-envelope-o"></i> {{data.cli_email}}</div>
              <div *ngIf="data.cli_telefono"><i class="fa fa-phone"></i> {{data.cli_telefono}}</div>
              <div *ngIf="data.cli_notas"><br>{{data.cli_notas}}</div>
            </li>
        </ul>
      </div>
      <div class="col-xs-6">
        <ul class="list-group">
            <li class="list-group-item active">Datos de la reserva</li>
            <li class="list-group-item datos">
              <div class="dato-destacado">#{{data.referencia}}</div>
              <div class="dato-destacado">{{data.alojamiento}}</div>
              <div>Precio: {{data.tarifa}}</div>
              <div>Llegada: {{data.fecha_llegada_format}}</div>
              <div>Horario de llegada: {{data.horario_llegada}}</div>
              <div>Salida: {{data.fecha_salida_format}}</div>
              <div>Horario de salida: {{data.horario_salida}}</div>
              <div>Adultos: {{data.adultos}}</div>
              <div>Niños<span *ngIf="data.ninos_rango_edad" class="text-muted font-11"> {{data.ninos_rango_edad}}</span>: {{data.ninos}}</div>
            </li>
            <li class="list-group-item active font-bold font-20">
              Total
              <div class="pull-right">{{data.total_format}} €</div>
              <div class="clearfix"></div>
            </li>
        </ul>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
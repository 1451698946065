import { ApplicationRef, Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let ICAL: any;
declare let Litepicker: any;

@Component({
  selector: 'app-confirmacion-reserva',
  templateUrl: './confirmacion-reserva.component.html',
  styleUrls: ['./confirmacion-reserva.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmacionReservaComponent implements OnInit {
  public data:any = {};
  public redsysparameters:string = '';
  public orderid:string = '';
  public token:string = '';

  constructor(
    public title: Title,
    public meta: Meta,
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    this.title.setTitle('Confirmación de reserva');
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.2)',
      imageResizeFactor: 0.5,
      text: 'Cargando datos ...'
    });
    //Redsys
    this.redsysparameters = this.globals.GetQueryString('Ds_MerchantParameters');
    if (this.redsysparameters && this.redsysparameters != '') {
      this.data.isredsys = true;
      let params = JSON.parse(atob(this.redsysparameters));
      this.data.redsys_order = params.Ds_Order;
      this.globals.Api('/pub-redsys-ok', {ds_merchant_parameters: this.globals.GetQueryString('Ds_MerchantParameters')}).subscribe(data => {
        if (!data || data.error) {
          $.LoadingOverlay("hide", true);
          this.ngZone.run(() => this.router.navigateByUrl('/reserva-error?Ds_MerchantParameters='+this.globals.GetQueryString('Ds_MerchantParameters'))).then();
          return;
        }
        this.CargarDatos();
      }, error => {
        $.LoadingOverlay("hide", true);
        this.ngZone.run(() => this.router.navigateByUrl('/reserva-error?Ds_MerchantParameters='+this.globals.GetQueryString('Ds_MerchantParameters'))).then();
      });
    }
    //Paypal
    this.orderid = this.globals.GetQueryString('orderid');
    if (this.orderid && this.orderid != '') {
      this.data.paypal_order = this.orderid;
      this.globals.Api('/pub-paypal-ok', {orderid: this.orderid}).subscribe(data => {
        if (!data || data.error) {
          $.LoadingOverlay("hide", true);
          this.ngZone.run(() => this.router.navigateByUrl('/reserva-error?orderid='+this.orderid)).then();
          return;
        }
        this.CargarDatos();
      }, error => {
        $.LoadingOverlay("hide", true);
        this.ngZone.run(() => this.router.navigateByUrl('/reserva-error?orderid='+this.orderid)).then();
      });
    }
    //Token
    this.token = this.globals.GetQueryString('token');
    if (this.token && this.token != '') {
      this.CargarDatos();
    }
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        window.parent.postMessage({
          url: window.location.href,
          height: $(window).height()
        }, '*');
        window.postMessage({
          url: window.location.href,
          height: $(window).height()
        }, '*');
      }
    });
    window.parent.postMessage({
      url: window.location.href,
      height: $(window).height()
    }, '*');
    window.postMessage({
      url: window.location.href,
      height: $(window).height()
    }, '*');
  }
  CargarDatos() {
    if (this.redsysparameters && this.redsysparameters != '') {
      this.globals.Api('/pub-get-reserva', {redsys_order: this.data.redsys_order}).subscribe(data => {
        $.LoadingOverlay("hide", true);
        if (!data) return;
        this.data = data;
        this.data.isredsys = true;
        this.appref.tick();
      }, error => {
        $.LoadingOverlay("hide", true);
      });
    } 
    if (this.orderid && this.orderid != '') {
      this.globals.Api('/pub-get-reserva', {paypal_order: this.data.paypal_order}).subscribe(data => {
        $.LoadingOverlay("hide", true);
        if (!data) return;
        this.data = data;
        this.data.isredsys = false;
        this.appref.tick();
      }, error => {
        $.LoadingOverlay("hide", true);
      });
    }
    if (this.token && this.token != '') {
      this.globals.Api('/pub-get-reserva', {reserva_token: this.token}).subscribe(data => {
        $.LoadingOverlay("hide", true);
        if (!data) return;
        this.data = data;
        this.data.isredsys = false;
        this.appref.tick();
      }, error => {
        $.LoadingOverlay("hide", true);
      });
    }
  }

}

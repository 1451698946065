import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let ICAL: any;
declare let Litepicker: any;

@Component({
  selector: 'app-cancelar-reserva',
  templateUrl: './cancelar-reserva.component.html',
  styleUrls: ['./cancelar-reserva.component.scss']
})
export class CancelarReservaComponent implements OnInit {
  public data:any = {};
  public token:string = '';
  public error:boolean = false;

  constructor(
    public title: Title,
    public meta: Meta,
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    this.title.setTitle('Confirmación de reserva');
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.2)',
      imageResizeFactor: 0.5,
      text: 'Cargando datos ...'
    });
    //Token
    this.token = this.globals.GetQueryString('token');
    if (this.token && this.token != '') {
      this.CargarDatos();
    } else {
      $.LoadingOverlay("hide", true);
      this.error = true;
    }
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        window.parent.postMessage({
          url: window.location.href,
          height: $(window).height()
        }, '*');
        window.postMessage({
          url: window.location.href,
          height: $(window).height()
        }, '*');
      }
    });
    window.parent.postMessage({
      url: window.location.href,
      height: $(window).height()
    }, '*');
    window.postMessage({
      url: window.location.href,
      height: $(window).height()
    }, '*');
  }
  CargarDatos() {
    if (this.token && this.token != '') {
      this.globals.Api('/pub-get-reserva', {reserva_token: this.token}).subscribe(data => {
        $.LoadingOverlay("hide", true);
        if (!data) {
          this.error = true;
          return;
        }
        this.data = data;
        this.appref.tick();
      }, error => {
        $.LoadingOverlay("hide", true);
        this.error = true;
      });
    }
  }
  CancelarReserva() {
    swal({
      title: "¿Cancelar esta reserva?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      $.LoadingOverlay("show", {
        background: 'rgba(255, 255, 255, 0.2)',
        imageResizeFactor: 0.5,
        text: 'Espere por favor ...'
      });
      $('#modal-reserva').modal('hide');
      this.globals.Api('/pub-cancelar-reserva', {reserva_token: this.token}).subscribe(data => {
        $.LoadingOverlay("hide", true);
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.data.id_estado = 4;
        this.data.estado = 'Pendiente cancelación';
        this.appref.tick();
        toastr.success(`Su reserva a pasado a "pendiente de cancelación"`, 'Solicitud enviada');
      }, error => {
        $.LoadingOverlay("hide", true);
      });
    });
  }

}

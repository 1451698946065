<ng-container *ngIf="id_cliente && id_cliente != ''">
    <div class="form-nueva-reserva {{vista}}">
        <ng-container *ngIf="vista == '' || vista == 'defecto'">
            <section class="step-fecha {{step == 'fecha' ? '' : 'hidden'}}">
                <div class="row row-small noselect">
                    <div class="col-xs-6 m-b-10">
                        <h5>Fecha</h5>
                        <div id="calendario"></div>
                        <ng-container *ngIf="alojamientos && alojamientos.length > 1">
                            <h5 class="m-t-20">Selecciona alojamiento</h5>
                            <ul class="list-group list-alojamientos">
                                <li class="list-group-item cursor {{item.visible ? null : 'hidden'}} {{item.selected ? 'active' : null}}" *ngFor="let item of alojamientos" (click)="SelectAlojamiento(item)">
                                    <img [src]="item.imagen" alt="" *ngIf="item.imagen">
                                    <div class="list-group-label">
                                        <span [innerHTML]="item.nombre"></span>
                                        <div class="subtitulo" *ngIf="item.subtitulo" [innerHTML]="item.subtitulo"></div>
                                    </div>
                                    <i class="fa fa-info-circle mas-info" (click)="VerDatosAlojamiento(item, $event)"></i>
                                </li>
                            </ul>
                        </ng-container>
                    </div>
                    <div class="col-xs-6">
                        <h5>Personas</h5>
                        <ul class="list-group">
                            <li class="list-group-item">
                                <i class="icofont-user-alt-1"></i> Adultos
                                <div class="selector-numero right">
                                    <a class="btn-menos" (click)="Menos('adultos')" [attr.disabled]="adultos <= 1"><i class="fa fa-minus-circle"></i></a>
                                    <span>{{adultos}}</span>
                                    <a class="btn-mas" (click)="Mas('adultos')" [attr.disabled]="adultos >= 50"><i class="fa fa-plus-circle"></i></a>
                                </div>
                                <div class="clearfix"></div>
                            </li>
                            <li class="list-group-item" *ngIf="!alojamiento || alojamiento.ninos">
                                Niños
                                <span *ngIf="alojamiento && alojamiento.ninos_rango_edad" class="text-muted font-12"><br>{{alojamiento.ninos_rango_edad}}</span>
                                <div class="selector-numero right">
                                    <a class="btn-menos" (click)="Menos('ninos')" [attr.disabled]="ninos <= 0"><i class="fa fa-minus-circle"></i></a>
                                    <span>{{ninos}}</span>
                                    <a class="btn-mas" (click)="Mas('ninos')" [attr.disabled]="ninos >= 50"><i class="fa fa-plus-circle"></i></a>
                                </div>
                                <div class="clearfix"></div>
                            </li>
                        </ul>
                        <ng-container *ngIf="totalfechas && totalfechas.total != ''">
                            <ul class="list-group list-total">
                                <li class="list-group-item">
                                    Precio noche
                                    <div class="pull-right">{{totalfechas.precio_noche}}</div>
                                    <div class="clearfix"></div>
                                </li>
                                <li class="list-group-item active font-bold font-20">
                                    Total
                                    <span class="font-normal" *ngIf="totalfechas.noches">({{totalfechas.noches}} noches)</span>
                                    <div class="pull-right">{{totalfechas.total}} €</div>
                                    <div class="clearfix"></div>
                                </li>
                            </ul>
                        </ng-container>
                        <div class="clearfix"></div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="m-t-20 text-right">
                    <a class="btn btn-primary btn-lg" (click)="Continuar()">
                        Continuar <i class="fa fa-chevron-right"></i>
                    </a>
                </div>
            </section>
            <section class="step-extras {{step == 'extras' ? '' : 'hidden'}}">
                <div class="row row-small">
                    <div class="col-xs-6 noselect">
                        <h5>Resumen de la reserva</h5>
                        <div class="card-resumen" *ngIf="alojamiento && totalfechas">
                            <img [src]="alojamiento.imagen" alt="" *ngIf="alojamiento.imagen">
                            <span class="card-title">{{alojamiento.nombre}}</span><br>
                            <span>LLegada: <b>{{totalfechas.fecha_llegada_format}}</b></span><br>
                            <span>Salida: <b>{{totalfechas.fecha_salida_format}}</b></span>
                            <div class="clearfix"></div>
                            <ul class="list-group list-total">
                                <li class="list-group-item">
                                    Noches
                                    <div class="pull-right">{{totalfechas.noches}}</div>
                                    <div class="clearfix"></div>
                                </li>
                                <li class="list-group-item">
                                    Precio noche
                                    <div class="pull-right">{{totalfechas.precio_noche}}</div>
                                    <div class="clearfix"></div>
                                </li>
                                <ng-container *ngIf="totalfechas.extras && totalfechas.extras.length">
                                    <li class="list-group-item" *ngFor="let item of totalfechas.extras">
                                        + {{item.nombre}}
                                        <div class="pull-right">
                                            <span class="font-bold">+ {{item.subtotal_format}} €</span><br>
                                            <small class="p-l-5">{{item.tipo_precio_texto}}</small>
                                        </div>
                                        <div class="clearfix"></div>
                                    </li>
                                    <li class="list-group-item">
                                        Extras
                                        <div class="pull-right">{{totalfechas.total_extras}} €</div>
                                        <div class="clearfix"></div>
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="!totalfechas.porcent_anticipo || !totalfechas.importe_anticipo">
                                    <li class="list-group-item active font-bold font-20">
                                        Total
                                        <div class="pull-right">{{totalfechas.total}} €</div>
                                        <div class="clearfix"></div>
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="totalfechas.porcent_anticipo && totalfechas.importe_anticipo">
                                    <li class="list-group-item">
                                        Total
                                        <div class="pull-right">{{totalfechas.total}} €</div>
                                        <div class="clearfix"></div>
                                    </li>
                                    <li class="list-group-item">
                                        Porcentaje de anticipo
                                        <div class="pull-right">{{totalfechas.porcent_anticipo}}%</div>
                                        <div class="clearfix"></div>
                                    </li>
                                    <li class="list-group-item active font-bold font-20">
                                        Importe
                                        <div class="pull-right">{{totalfechas.importe_anticipo}} €</div>
                                        <div class="clearfix"></div>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xs-6 noselect">
                        <h5>Extras y suplementos</h5>
                        <div class="list-group extras" *ngIf="alojamiento && alojamiento.extras  && alojamiento.extras.length">
                            <div class="list-group-item" *ngFor="let item of alojamiento.extras">
                                <div class="btn-check btn-{{item.tipo_precio}}">
                                    <label class="csscheckbox csscheckbox-warning">
                                        <input type="checkbox" [checked]="item.checked" (change)="SetExtra(item)"><span></span>
                                        <div class="texto" [innerHTML]="item.nombre"></div>
                                    </label>
                                    <i class="fa fa-info-circle icon" *ngIf="item.descripcion" (click)="VerExtraDescripcion(item)"></i>
                                    <strong (click)="VerExtraDescripcion(item)">{{item.precio_format}} €
                                        <br><small>{{item.tipo_precio_texto}}</small>
                                    </strong>
                                </div>
                                <div class="m-t-10" *ngIf="item.tipo_precio == 'por-persona' || item.tipo_precio == 'por-persona-dia'">
                                    <i class="icofont-user-alt-1"></i> Personas
                                    <div class="selector-numero right">
                                        <a class="btn-menos" [attr.disabled]="item.personas <= 1" (click)="MenosExtra(item)"><i class="fa fa-minus-circle"></i></a>
                                        <span>{{item.personas}}</span>
                                        <a class="btn-mas" [attr.disabled]="item.personas >= 50" (click)="MasExtra(item)"><i class="fa fa-plus-circle"></i></a>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="btn-botones-finales">
                            <a class="btn btn-default btn-lg btn-atras" (click)="Atras()"><i class="fa fa-chevron-left"></i> Atrás</a>
                            <a class="btn btn-primary btn-lg btn-reservar" [attr.disabled]="(!totalfechas || !totalfechas.total ? true : null)" (click)="Continuar()">Continuar</a>
                        </div>
                    </div>
                </div>
            </section>
            <section class="step-resumen {{step == 'resumen' ? '' : 'hidden'}}">
                <div class="row row-small">
                    <div class="col-xs-6 noselect">
                        <h5>Resumen de la reserva</h5>
                        <div class="card-resumen" *ngIf="alojamiento && totalfechas">
                            <img [src]="alojamiento.imagen" alt="" *ngIf="alojamiento.imagen">
                            <span class="card-title">{{alojamiento.nombre}}</span><br>
                            <span>LLegada: <b>{{totalfechas.fecha_llegada_format}}</b></span><br>
                            <span>Salida: <b>{{totalfechas.fecha_salida_format}}</b></span>
                            <div class="clearfix"></div>
                            <ul class="list-group list-total">
                                <li class="list-group-item">
                                    Noches
                                    <div class="pull-right">{{totalfechas.noches}}</div>
                                    <div class="clearfix"></div>
                                </li>
                                <li class="list-group-item">
                                    Precio noche
                                    <div class="pull-right">{{totalfechas.precio_noche}}</div>
                                    <div class="clearfix"></div>
                                </li>
                                <ng-container *ngIf="totalfechas.extras && totalfechas.extras.length">
                                    <li class="list-group-item" *ngFor="let item of totalfechas.extras">
                                        + {{item.nombre}}
                                        <div class="pull-right">
                                            <span class="font-bold">+ {{item.subtotal_format}} €</span><br>
                                            <small>{{item.tipo_precio_texto}}</small>
                                        </div>
                                        <div class="clearfix"></div>
                                    </li>
                                    <li class="list-group-item">
                                        Extras
                                        <div class="pull-right">{{totalfechas.total_extras}} €</div>
                                        <div class="clearfix"></div>
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="!totalfechas.porcent_anticipo || !totalfechas.importe_anticipo">
                                    <li class="list-group-item active font-bold font-20">
                                        Total
                                        <div class="pull-right">{{totalfechas.total}} €</div>
                                        <div class="clearfix"></div>
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="totalfechas.porcent_anticipo && totalfechas.importe_anticipo">
                                    <li class="list-group-item">
                                        Total
                                        <div class="pull-right">{{totalfechas.total}} €</div>
                                        <div class="clearfix"></div>
                                    </li>
                                    <li class="list-group-item">
                                        Porcentaje de anticipo
                                        <div class="pull-right">{{totalfechas.porcent_anticipo}}%</div>
                                        <div class="clearfix"></div>
                                    </li>
                                    <li class="list-group-item active font-bold font-20">
                                        Importe
                                        <div class="pull-right">{{totalfechas.importe_anticipo}} €</div>
                                        <div class="clearfix"></div>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                        <h5>Formas de pago</h5>
                        <div class="row row-small pago">
                            <div class="col-xs-12 col-sm-6" *ngFor="let item of formas_pago">
                                <a class="btn btn-default btn-{{item.slug}}" (click)="SetFormaPago(item)">
                                    <label class="csscheckbox csscheckbox-warning"><input type="checkbox" [checked]="item.checked"><span></span></label>
                                    <span class="texto" [innerHTML]="item.forma_pago"></span>
                                </a>
                            </div>
                        </div>
                        
                        <ng-container *ngIf="admin && tienealgunapasarela">
                            <hr>
                            <h5>Pedir anticipo</h5>
                            <div class="row row-small">
                                <div class="col-xs-12 noselect">
                                    <div class="form-group">
                                    <label>Importe a pedir</label>
                                    <input type="number" class="form-control" [(ngModel)]="data.anticipo_importe" maxlength="80">
                                    </div>
                                </div>
                            </div>
                            <div class="row row-small">
                                <div class="col-xs-6 noselect">
                                    <div class="form-group">
                                    <label>Fecha expiración</label>
                                    <input id="txt-fecha-expiracion" type="date" class="form-control" maxlength="20">
                                    </div>
                                </div>
                                <div class="col-xs-6 noselect">
                                    <div class="form-group">
                                    <label>Hora expiración</label>
                                    <input id="txt-hora-expiracion" type="time" class="form-control" maxlength="5" step="300">
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        
                    </div>
                    <div class="col-xs-6">
                        <h5 class="noselect">Datos personales</h5>
                        <div class="row row-small datos-personales">
                            <div class="col-xs-6 col-nombre">
                                <div class="form-group">
                                  <label>Nombre <span class="text-danger">*</span></label>
                                  <input type="text" class="form-control" [(ngModel)]="data.cli_nombre" maxlength="80">
                                </div>
                            </div>
                            <div class="col-xs-6 col-apellidos">
                                <div class="form-group">
                                  <label>Apellidos <span class="text-danger">*</span></label>
                                  <input type="text" class="form-control" [(ngModel)]="data.cli_apellidos" maxlength="80">
                                </div>
                            </div>
                            <ng-container *ngIf="globals.config && globals.config.campo_dni">
                                <div class="col-xs-6 col-dni">
                                    <div class="form-group">
                                      <label>DNI <span class="text-danger">*</span></label>
                                      <input type="text" class="form-control" [(ngModel)]="data.cli_nifcif" maxlength="20">
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="globals.config && globals.config.campo_direccion">
                                <div class="col-sm-8 col-xs-12 col-direccion">
                                    <div class="form-group">
                                      <label>Direcci&oacute;n</label>
                                      <input type="text" class="form-control" [(ngModel)]="data.cli_direccion" maxlength="200">
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="globals.config && globals.config.campo_codpostal">
                                <div class="col-sm-4 col-xs-12 col-codpostal">
                                    <div class="form-group">
                                      <label>C&oacute;d. postal</label>
                                      <input type="text" class="form-control" [(ngModel)]="data.cli_codpostal" maxlength="10" (change)="DatosLocalidad()">
                                    </div>
                                </div>
                            </ng-container>
                            <div class="col-xs-6 col-localidad">
                                <div class="form-group">
                                  <label>Localidad <span class="text-danger">*</span></label>
                                  <input type="text" class="form-control" [(ngModel)]="data.cli_localidad" maxlength="80">
                                </div>
                            </div>
                            <div class="col-xs-6 col-provincia">
                                <div class="form-group">
                                  <label>Provincia <span class="text-danger">*</span></label>
                                  <input type="text" class="form-control" [(ngModel)]="data.cli_provincia" maxlength="80">
                                </div>
                            </div>
                            <div class="col-xs-6 col-telefono">
                                <div class="form-group">
                                  <label>Tel&eacute;fono <span class="text-danger">*</span></label>
                                  <input type="tel" class="form-control" [(ngModel)]="data.cli_telefono" maxlength="20">
                                </div>
                            </div>
                            <div class="col-xs-6 col-email">
                                <div class="form-group">
                                  <label class="">Email <span class="text-danger">*</span></label>
                                  <input type="email" class="form-control"[(ngModel)]="data.cli_email" maxlength="80">
                                </div>
                            </div>
                            <div class="col-sm-12 col-xs-12 col-notas">
                                <div class="form-group">
                                  <label>Notas</label>
                                  <textarea class="form-control" rows="2" [(ngModel)]="data.cli_notas" maxlength="500"></textarea>
                                </div>
                            </div>
                            <div class="col-sm-12 col-xs-12 col-politica m-t--10" *ngIf="globals.config.url_politica_privacidad">
                                <label class="csscheckbox csscheckbox-warning" (click)="SetPolitica()"><input type="checkbox" [checked]="data.politica"><span></span>
                                    He leído y acepto la <a [href]="(globals.config.url_politica_privacidad ? globals.config.url_politica_privacidad : '#')" target="_blank">política de privacidad</a>
                                </label>
                            </div>
                            <div class="col-sm-12 col-xs-12 col-stripe" *ngIf="data && data.cli_tipo_pago_slug == 'stripe'">
                                <div class="form-group">
                                    <label>Introduce tu número de tarjeta</label>
                                    <div class="form-control" style="height: auto;">
                                        <div id="stripe-form" style="display:none;">
                                            <div id="card-element"></div>
                                            <div id="card-errors" role="alert"></div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="btn-botones-finales">
                            <a class="btn btn-default btn-lg btn-atras" (click)="Atras()"><i class="fa fa-chevron-left"></i> Atrás</a>
                            <a class="btn btn-primary btn-lg btn-reservar" [attr.disabled]="(!totalfechas || !totalfechas.total ? true : null)" (click)="Reservar()">Reservar</a>
                        </div>
                    </div>
                </div>
            </section>
            <div class="blackdrop" style="display:none"></div>
            <div class="iframe-container">
                <iframe id="iframe-addon-payments" frameborder="0" style="display:none"></iframe>
            </div>
        </ng-container>
        <ng-container *ngIf="vista == 'calendario'">
            <h5>Disponibilidad <span *ngIf="alojamiento" class="font-bold text-primary">{{alojamiento.nombre}}</span></h5>
            <div id="calendario"></div>
            <ng-container *ngIf="alojamientos && alojamientos.length > 1">
                <h5>Alojamiento</h5>
                <ul class="list-group list-alojamientos">
                    <li class="list-group-item cursor {{item.selected ? 'active' : null}}" *ngFor="let item of alojamientos" (click)="SelectAlojamientoCalendario(item)">
                        <img [src]="item.imagen" alt="" *ngIf="item.imagen">
                        <span [innerHTML]="item.nombre"></span>
                        <div class="subtitulo" *ngIf="item.subtitulo" [innerHTML]="item.subtitulo"></div>
                    </li>
                </ul>
            </ng-container>
            <ng-container *ngIf="globals.config && globals.config.url_reservar">
                <div class="m-t-20 m-b-20 text-center">
                    <a class="btn btn-primary btn-lg" [innerHTML]="globals.config.texto_boton_reservar" [href]="globals.config.url_reservar" target="_parent"></a>
                </div>
            </ng-container>
            <div class="clearfix"></div>
        </ng-container>
    </div>
    <div class="modal inmodal fade" id="modal-alojamiento" tabindex="-1" role="dialog"  aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
                    <h4 class="modal-title font-bold"></h4>
                    <div class="modal-subtitle" *ngIf="dataalojamiento.subtitulo" [innerHTML]="dataalojamiento.subtitulo"></div>
                </div>
                <div class="modal-body">
                    <ng-container *ngIf="dataalojamiento && dataalojamiento.nombre">
                        <div class="row">
                            <div class="col-xs-12 {{dataalojamiento.imagen ? 'col-sm-4' : 'hidden'}}">
                                <img [src]="dataalojamiento.imagen" alt="Imagen Alojamiento" class="imagen-alojamiento">
                            </div>
                            <div class="col-xs-12 {{dataalojamiento.imagen ? 'col-sm-8' : null}}">
                                <div [innerHTML]="dataalojamiento.descripcion" class="descripcion"></div>
                                <div class="precio-container" *ngIf="dataalojamiento.precio">
                                    <span class="precio">
                                        <b>{{globals.FloatES(dataalojamiento.precio)}} €</b>
                                        / noche
                                    </span>
                                </div>
                                <ng-container *ngIf="dataalojamiento.imagenes && dataalojamiento.imagenes.length">
                                    <section>
                                        <ul class="galeria">
                                            <li class="galeria-item" *ngFor="let item of dataalojamiento.imagenes;let i = index">
                                                <a [href]="item.imagen" data-lightbox="galeria" data-title="Imagen {{i+1}}">
                                                    <img [src]="item.imagen" alt="Imagen Galería">
                                                </a>
                                            </li>
                                        </ul>
                                    </section>
                                </ng-container>
                                <ng-container *ngIf="dataalojamiento.caracteristicas && dataalojamiento.caracteristicas.length">
                                    <section>
                                        <h4>Características</h4>
                                        <ul class="caracteristicas">
                                            <li class="caracteristica" *ngFor="let item of dataalojamiento.caracteristicas">
                                                <i class="fa {{item.icono}}"></i>
                                                <span [innerHTML]="item.caracteristica"></span>
                                            </li>
                                        </ul>
                                    </section>
                                </ng-container>
                                <ng-container *ngIf="dataalojamiento.servicios && dataalojamiento.servicios.length">
                                    <section>
                                        <h4>Servicios</h4>
                                        <ul class="caracteristicas">
                                            <li class="caracteristica" *ngFor="let item of dataalojamiento.servicios">
                                                <i class="fa {{item.icono}}"></i>
                                                <span [innerHTML]="item.servicio"></span>
                                            </li>
                                        </ul>
                                    </section>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="modal-footer" style="text-align:center;">
                    <button type="button" class="btn btn-primary" data-dismiss="modal">Aceptar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal inmodal fade" id="modal-info" tabindex="-1" role="dialog"  aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
                    <h4 class="modal-title font-bold"></h4>
                </div>
                <div class="modal-body">
                    <div [innerHTML]="info"></div>
                </div>
                <div class="modal-footer" style="text-align:center;">
                    <button type="button" class="btn btn-primary" data-dismiss="modal">Aceptar</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
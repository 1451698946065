<div id="sidebar" *ngIf="globals && globals.me && globals.me.id_rol == 0">
    <!-- Sidebar Brand -->
    <div id="sidebar-brand" class="themed-background {{globals.config && globals.config.logo_menu ? 'sidebar-brand-cliente' : null}}">
        <a [routerLink]="['/dashboard']" class="sidebar-title">
            <ng-container *ngIf="globals.config && globals.config.logo_menu">
                <img [src]="globals.config.logo_menu" alt="">
            </ng-container>
            <ng-container *ngIf="!globals.config || !globals.config.logo_menu">
                <span class="sidebar-nav-mini-hide m-l-10">SPG<strong>Host</strong></span>
            </ng-container>
        </a>
    </div>
    <!-- END Sidebar Brand -->

    <!-- Wrapper for scrolling functionality -->
    <div id="sidebar-scroll">
        <!-- Sidebar Content -->
        <div class="sidebar-content">
            <!-- Sidebar Navigation -->
            <ul class="sidebar-nav">
                <li>
                    <a [routerLink]="['/dashboard']" routerLinkActive="active"><i class="gi gi-home sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Inicio</span></a>
                </li>
                <li>
                    <a [routerLink]="['/config-app']" routerLinkActive="active"><i class="icofont-settings sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Configuración</span></a>
                </li>
                <li>
                    <a class="sidebar-nav-menu cursor" 
                        [class.open]="rlaclientes.isActive"
                        [class.open]="rlaclientes.isActive"
                        >
                        <i class="fa fa-chevron-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
                        <i class="icofont-ui-user-group sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Clientes</span>
                    </a>
                    <ul>
                        <li>
                            <a [routerLink]="['/clientes-spa']" routerLinkActive="active" #rlaclientes="routerLinkActive">Todos los clientes</a>
                        </li>
                        <li>
                            <a [routerLink]="['/clientes-spa/add']" routerLinkActive="active" #rlaclientes="routerLinkActive"><i class="fa fa-plus"></i> Nuevo cliente</a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="!globals.cliente">
                    <a href="javascript:void(0);" class="sidebar-nav-menu" [class.open]="rlausuarios.isActive"><i class="fa fa-chevron-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
                        <i class="fa fa-users sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Usuarios</span></a>
                    <ul>
                        <li>
                            <a [routerLink]="['/usuarios']" routerLinkActive="active" #rlausuarios="routerLinkActive">Todos los usuarios</a>
                        </li>
                        <li>
                            <a [routerLink]="['/usuarios/add']" routerLinkActive="active" #rlausuarios="routerLinkActive"><i class="fa fa-plus"></i> Nuevo usuario</a>
                        </li>
                    </ul>
                </li>
            </ul>
            
            <ul class="sidebar-nav" *ngIf="globals.cliente">
                <li class="bg-header">
                    <a href="javascript:void(0);" style="line-height: 1.1;padding: 10px"><i class="gi gi-home sidebar-nav-icon" style="display: inline;height: 30px;float: left;padding-top: 5px;"></i> <span class="sidebar-nav-mini-hide">{{globals.cliente.empresa}}</span></a>
                    <div class="sub-tarifa sidebar-nav-mini-hide">{{globals.cliente.tarifa}}</div>
                </li>
                <li>
                    <a [routerLink]="['/configuracion']" routerLinkActive="active"><i class="icofont-settings sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Configuración</span></a>
                </li>
                <li>
                    <a href="javascript:void(0);" class="sidebar-nav-menu" [class.open]="rlaalojamientos.isActive"><i class="fa fa-chevron-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
                        <i class="icofont-hotel sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Alojamientos</span></a>
                    <ul>
                        <li>
                            <a [routerLink]="['/alojamientos']" routerLinkActive="active" #rlaalojamientos="routerLinkActive">Todos los alojamientos</a>
                        </li>
                        <li>
                            <a [routerLink]="['/temporadas']" routerLinkActive="active" #rlaalojamientos="routerLinkActive"><i class="icofont-sun-rise"></i> Temporadas</a>
                        </li>
                        <li>
                            <a [routerLink]="['/alojamientos/add']" routerLinkActive="active" #rlaalojamientos="routerLinkActive"><i class="fa fa-plus"></i> Nuevo alojamiento</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a [routerLink]="['/calendario']" routerLinkActive="active"><i class="icofont-calendar sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Calendario</span></a>
                </li>
                <li>
                    <a [routerLink]="['/reservas']" routerLinkActive="active"><i class="icofont-travelling sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Reservas</span></a>
                </li>
                <!-- <li>
                    <a href="javascript:void(0);" class="sidebar-nav-menu" [class.open]="rlareservas.isActive"><i class="fa fa-chevron-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
                        <i class="icofont-travelling sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Reservas</span></a>
                    <ul>
                        <li>
                            <a [routerLink]="['/reservas']" routerLinkActive="active" #rlareservas="routerLinkActive">Listado de reservas</a>
                        </li>
                        <li>
                            <!-- <a [routerLink]="['/reservas/add']" routerLinkActive="active" #rlareservas="routerLinkActive"><i class="fa fa-plus"></i> Nueva reserva</a> - ->
                            <a (click)="NuevaReserva()"><i class="fa fa-plus"></i> Nueva reserva</a>
                        </li>
                    </ul>
                </li>
                -->
                <li>
                    <a [routerLink]="['/clientes']" routerLinkActive="active"><i class="icofont-ui-user-group sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Clientes</span></a>
                </li>
                <li>
                    <a href="javascript:void(0);" class="sidebar-nav-menu" [class.open]="rlausuarios.isActive"><i class="fa fa-chevron-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
                        <i class="fa fa-users sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Usuarios</span></a>
                    <ul>
                        <li>
                            <a [routerLink]="['/usuarios']" routerLinkActive="active" #rlausuarios="routerLinkActive">Todos los usuarios</a>
                        </li>
                        <li>
                            <a [routerLink]="['/usuarios/add']" routerLinkActive="active" #rlausuarios="routerLinkActive"><i class="fa fa-plus"></i> Nuevo usuario</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <!-- END Sidebar Content -->
    </div>
    <!-- END Wrapper for scrolling functionality -->

    <!-- Sidebar Extra Info -->
    <div class="copyright sidebar-nav-mini-hide">
        <div class="copy-title">SPG Host</div>
        <a class="copy-enlace" href="https://pecesgordos.es" target="_blank">© Peces Gordos Estudio</a>
    </div>
    <!-- END Sidebar Extra Info -->
</div>
<div id="sidebar"  *ngIf="globals && globals.me && globals.me.id_rol != 0">
    <!-- Sidebar Brand -->
    <div id="sidebar-brand" class="themed-background {{globals.config && globals.config.logo_menu ? 'sidebar-brand-cliente' : null}}">
        <a [routerLink]="['/dashboard']" class="sidebar-title">
            <ng-container *ngIf="globals.config && globals.config.logo_menu">
                <img [src]="globals.config.logo_menu" alt="">
            </ng-container>
            <ng-container *ngIf="!globals.config || !globals.config.logo_menu">
                <span class="sidebar-nav-mini-hide m-l-10">SPG<strong>Host</strong></span>
            </ng-container>
        </a>
    </div>
    <!-- END Sidebar Brand -->

    <!-- Wrapper for scrolling functionality -->
    <div id="sidebar-scroll">
        <!-- Sidebar Content -->
        <div class="sidebar-content">
            <!-- Sidebar Navigation -->
            <!-- ADMIN -->
            <ng-container *ngIf="globals.me.id_rol == 1 || globals.me.id_rol == 2">
                <ul class="sidebar-nav">
                    <li>
                        <a [routerLink]="['/dashboard']" routerLinkActive="active"><i class="gi gi-home sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Inicio</span></a>
                    </li>
                    <li class="sidebar-separator">
                        <i class="fa fa-ellipsis-h"></i>
                    </li>
                    <li *ngIf="!globals.me.tarifa || globals.me.tarifa != 'spg-host-lite'">
                        <a [routerLink]="['/configuracion']" routerLinkActive="active"><i class="icofont-settings sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Configuración</span></a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" class="sidebar-nav-menu" [class.open]="rlaalojamientos.isActive"><i class="fa fa-chevron-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
                            <i class="icofont-hotel sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Alojamientos</span></a>
                        <ul>
                            <li>
                                <a [routerLink]="['/alojamientos']" routerLinkActive="active" #rlaalojamientos="routerLinkActive">Todos los alojamientos</a>
                            </li>
                            <li *ngIf="!globals.me.tarifa || globals.me.tarifa != 'spg-host-lite'">
                                <a [routerLink]="['/temporadas']" routerLinkActive="active" #rlaalojamientos="routerLinkActive"><i class="icofont-sun-rise"></i> Temporadas</a>
                            </li>
                            <li>
                                <a [routerLink]="['/alojamientos/add']" routerLinkActive="active" #rlaalojamientos="routerLinkActive"><i class="fa fa-plus"></i> Nuevo alojamiento</a>
                            </li>
                        </ul>
                    </li>
                    <li *ngIf="!globals.me.tarifa || globals.me.tarifa != 'spg-host-lite'">
                        <a [routerLink]="['/calendario']" routerLinkActive="active"><i class="icofont-calendar sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Calendario</span></a>
                    </li>
                    <li *ngIf="!globals.me.tarifa || globals.me.tarifa != 'spg-host-lite'">
                        <a [routerLink]="['/reservas']" routerLinkActive="active"><i class="icofont-travelling sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Reservas</span></a>
                    </li>
                    <!-- <li *ngIf="!globals.me.tarifa || globals.me.tarifa != 'spg-host-lite'">
                        <a href="javascript:void(0);" class="sidebar-nav-menu" [class.open]="rlareservas.isActive"><i class="fa fa-chevron-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
                            <i class="icofont-travelling sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Reservas</span></a>
                        <ul>
                            <li>
                                <a [routerLink]="['/reservas']" routerLinkActive="active" #rlareservas="routerLinkActive">Listado de reservas</a>
                            </li>
                            <li>
                                <!-- <a [routerLink]="['/reservas/add']" routerLinkActive="active" #rlareservas="routerLinkActive"><i class="fa fa-plus"></i> Nueva reserva</a> - ->
                                <a (click)="NuevaReserva()"><i class="fa fa-plus"></i> Nueva reserva</a>
                            </li>
                        </ul>
                    </li>
                    -->
                    <li *ngIf="!globals.me.tarifa || globals.me.tarifa != 'spg-host-lite'">
                        <a [routerLink]="['/clientes']" routerLinkActive="active"><i class="icofont-ui-user-group sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Clientes</span></a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" class="sidebar-nav-menu" [class.open]="rlausuarios.isActive"><i class="fa fa-chevron-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
                            <i class="fa fa-users sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Usuarios</span></a>
                        <ul>
                            <li>
                                <a [routerLink]="['/usuarios']" routerLinkActive="active" #rlausuarios="routerLinkActive">Todos los usuarios</a>
                            </li>
                            <li>
                                <a [routerLink]="['/usuarios/add']" routerLinkActive="active" #rlausuarios="routerLinkActive"><i class="fa fa-plus"></i> Nuevo usuario</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </ng-container>
            <!-- CLIENTE -->
            <ng-container *ngIf="globals.me.id_rol == 3">
                <ul class="sidebar-nav">
                    <li>
                        <a [routerLink]="['/dashboard']" routerLinkActive="active"><i class="gi gi-home sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Inicio</span></a>
                    </li>
                    <li class="sidebar-separator">
                        <i class="fa fa-ellipsis-h"></i>
                    </li>
                    <li>
                        <a [routerLink]="['/mi-perfil']" routerLinkActive="active"><i class="icofont-user sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Mi perfil</span></a>
                    </li>
                    <li>
                        <a [routerLink]="['/mis-reservas']" routerLinkActive="active"><i class="icofont-travelling sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Mis reservas</span></a>
                    </li>
                </ul>
            </ng-container>
            <!-- END Sidebar Navigation -->
        </div>
        <!-- END Sidebar Content -->
    </div>
    <!-- END Wrapper for scrolling functionality -->

    <!-- Sidebar Extra Info -->
    <div class="licencia sidebar-nav-mini-hide" *ngIf="globals.me && globals.me.licencia">
        <div class="licencia-content-success" *ngIf="!globals.me.licencia.error">
            <b>Licencia activa</b>
            <p *ngIf="globals.me.licencia.fecha_expiracion_format">Hasta el: <span>{{globals.me.licencia.fecha_expiracion_format}}</span></p>
        </div>
        <div class="licencia-content-error" *ngIf="globals.me.licencia.error">
        <b>¡Licencia expirada!</b>
        <a href="https://pecesgordos.es" target="_blank">Por favor, contacte con <b>Peces Gordos Estudio</b> para renovar la licencia.</a>
        </div>
    </div>
    <div class="copyright sidebar-nav-mini-hide">
        <div class="copy-title">SPG Host</div>
        <a class="copy-enlace" href="https://pecesgordos.es" target="_blank">© Peces Gordos Estudio</a>
    </div>
    <!-- END Sidebar Extra Info -->
</div>
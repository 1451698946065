<!-- Full Background -->
<!-- For best results use an image with a resolution of 1280x1280 pixels (prefer a blurred image for smaller file size) -->
<img [src]="globals.config.bg_login" alt="Full Background" class="full-bg animation-pulseSlow" style="object-fit:cover;" *ngIf="globals.config && globals.config.bg_login">
<img src="assets/bg-login-2.jpg" alt="Full Background" class="full-bg animation-pulseSlow" style="object-fit:cover;opacity:.8;filter:grayscale(1);" *ngIf="!globals.config || !globals.config.bg_login">
<!-- END Full Background -->

<!-- Login Container -->
<div id="login-container">
    <!-- Login Header -->
    <h1 class="h2 text-light text-center animation-pullDown" *ngIf="globals.config && globals.config.logo_login">
        <img [src]="globals.config.logo_login" class="logo">
    </h1>
    <h1 class="h2 text-light text-center animation-pullDown" *ngIf="!globals.config || !globals.config.logo_login">
        <img src="assets/logo-negativo.svg" class="logo">
    </h1>
    <!-- END Login Header -->

    <!-- Login Block -->
    <div class="block animation-fadeInQuick {{!recuperarclave ? '' : 'hidden'}}">
        <!-- Login Title -->
        <div class="block-title">
            <h2>Panel de administraci&oacute;n</h2>
        </div>
        <!-- END Login Title -->

        <!-- Login Form -->
        <div id="form-login" class="form-horizontal">
            <div class="form-group">
                <!-- <label for="login-email" class="col-xs-12">Email</label> -->
                <div class="col-xs-12">
                    <input type="text" id="txtEmail" name="login-email" class="form-control" [(ngModel)]="email" placeholder="Email" autocomplete="off">
                </div>
            </div>
            <div class="form-group">
                <!-- <label for="login-password" class="col-xs-12">Contrase&ntilde;a</label> -->
                <div class="col-xs-12">
                    <div class="input-group input-group-clave">
                        <input type="password" id="txtClave" name="login-password" class="form-control" [(ngModel)]="clave" placeholder="Contrase&ntilde;a" autocomplete="off">
                        <div class="input-group-append cursor" (click)="VerClave()">
                          <span class="input-group-addon"><i class="fa fa-eye"></i></span>
                        </div>
                    </div>
                </div>
            </div>
            <a id="btnAcceder" class="btn btn-effect-ripple btn-primary btn-block text-uppercase" (click)="Login()">Acceder <i class="fa fa-chevron-right m-l-10"></i></a>       
            <a class="display-block text-center cursor m-t-10" (click)="recuperarclave = true"><small>¿Has olvidado tu contrase&ntilde;a?</small></a>
        </div>
        <!-- END Login Form -->
    </div>
    <div class="block animation-fadeInQuick {{recuperarclave ? '' : 'hidden'}}">
        <!-- Login Title -->
        <div class="block-title">
            <h2>Recuperaci&oacute;n contrase&ntilde;a</h2>
        </div>
        <div id="form-login" class="form-horizontal">
            <p>Introduce tu correo y te enviaremos tu nueva contrase&ntilde;a</p>
            <div class="form-group">
                <div class="col-xs-12">
                    <input id="txtEmailRecuperar" type="email" class="form-control" placeholder="Email" [(ngModel)]="email" required>
                </div>
            </div>
            <div class="text-center">
                <a id="btnRecuperarClave" class="btn btn-effect-ripple btn-primary btn-block cursor" (click)="RecuperarClave()">Enviar correo recuperaci&oacute;n</a><br>
                <button type="button" class="btn btn-default m-t-10 m-b-20" (click)="recuperarclave = false">Atr&aacute;s</button>
            </div>
        </div>
    </div>
    <!-- END Login Block -->

    <!-- Footer -->
    <footer class="footer-login animation-pullUp">
        <a href="https://pecesgordos.es" target="_blank">
            <img src="assets/logo-negativo.svg"> <strong>SPG Host</strong><br>
            <span>&copy; Peces Gordos Estudio</span>
        </a>
    </footer>
    <!-- END Footer -->
</div>
<!-- END Login Container -->
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

declare let $: any;
declare let swal: any;
declare let moment: any;
declare let config: any;

@Injectable()
export class Globals {
    public api_url: string = 'https://spghost.pecesgordos.es/api';
    public token: string = '';
    public me:any = {};
    public passData:any = {};
    public config:any = {};
    public idioma:string = 'es';
    public cliente:any = null;

    constructor(private http: HttpClient) { }

    public options() : any {
        let headers:HttpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json;charset=utf-8')
        .set('Accept', 'application/json');
        return {headers: headers};
    }
    public Api(method:string, parameters:any = null) : Observable<any> {
        if (this.token && this.token != '') {
            if (parameters) parameters.token = this.token;
            if (!parameters) parameters = {token: this.token};
        }
        if (this.cliente && this.cliente.id) {
            if (parameters && !parameters.id_cliente) parameters.id_cliente = this.cliente.id;
            if (!parameters) parameters = {id_cliente: this.cliente.id};
        }
        parameters = JSON.stringify(parameters);
        return this.http.post(this.api_url+method, parameters, this.options());
    }
    public Literal(key:string) : string {
        let value:string = '';
        if (window['literales'] && window['literales'][this.idioma]) {
            value = window['literales'][this.idioma][key];
        }
        return value;
    }

    public ValidateEmail(email:string) : boolean {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    public ValidatePassword(pass:string) : boolean {
        var letter = /[a-zA-Z]/;
        var number = /[0-9]/;
        var reg8caracteres = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/; //Minimum eight characters, at least one letter and one number
        var reg6caracteres = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/; //Minimum eight characters, at least one letter and one number
        var ok:boolean = true;
        if (pass.length < 4 || !reg6caracteres.test(pass)) ok = false;
        return ok;
    }
    public PostedDate(date:any) {
		var fecha = date.toString().split(' ')[0];
		var hora = date.toString().split(' ')[1];

		//var y = parseInt(fecha.split('/')[2]);
		//var m = parseInt(fecha.split('/')[1])-1;
		//var d = parseInt(fecha.split('/')[0]);
		var y = parseInt(fecha.split('-')[0]);
		var m = parseInt(fecha.split('-')[1])-1;
		var d = parseInt(fecha.split('-')[2]);
		var h = parseInt(hora.split(':')[0]);
		var min = parseInt(hora.split(':')[1]);
		var s = parseInt(hora.split(':')[2]);
		var formatedDate = new Date(y, m, d, h, min, s);
        var seconds = Math.floor((new Date() as any - (formatedDate as any)) / 1000);

        var interval = Math.floor(seconds / 31536000);

        if (interval > 1) {return interval + " años";}
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {return interval + " meses";}
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {return interval + " días";}
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {return interval + " horas";}
        interval = Math.floor(seconds / 60);
        if (interval > 1) {return interval + " minutos";}
        return Math.floor(seconds) + " segundos";
    }
    public PostedDateToday(fecha:any, fecha_default:any = '') {
        let today = moment().format('YYYY-MM-DD');
        fecha = moment(fecha);
        if (fecha.isSame(today)) {
            return 'HOY';
        }
        today = moment(today);
        let tomorrow = moment(today.add(1, 'day')).format('YYYY-MM-DD');
        if (fecha.isSame(tomorrow)) {
            return 'MAÑANA';
        }
        return fecha_default;
    }
    public DateString(date:string) {
        // return new Date(date).toLocaleDateString();
        return moment(date).format('DD/MM/YYYY');
    }
    public DateTimeString(date:string) {
        // return new Date(date).toLocaleTimeString();
        return moment(date).format('DD/MM/YYYY hh:mm:ss');
    }
    public isValidDate(date:string) {
        let matches = /^(\d+)[-\/](\d+)[-\/](\d+)$/.exec(date);
        if (matches == null) return false;
        let d = parseInt(matches[1]);
        let m = parseInt(matches[2]);
        let y = parseInt(matches[3]);
        // if (y.toString().length < 4) {
        //     y += 2000;
        // }
        if (y > 2100 || y < 1900) return false; 
        let composedDate = new Date(y+'/'+m+'/'+d);
        return composedDate.getDate() == d && composedDate.getMonth()+1 == m && composedDate.getFullYear() == y;
    }
    public FloatES(valor, decimals = 2) {
        if (!valor) return '';
        valor = valor.toString().replace(',', '.');
        // return (parseFloat(valor) % 1 != 0 ? parseFloat(valor).toFixed(2).replace('.', ',') : parseFloat(valor).toString());
        if (parseFloat(valor) % 1 == 0) {
            valor = parseFloat(valor).toString();
        } else {
            valor = parseFloat(valor).toFixed(decimals).replace('.', ',');
        }
        if (valor.indexOf(',') == -1) {
            valor = valor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } else {
            valor = valor.split(',')[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")+','+valor.split(',')[1];
        }
        return valor;
    }
    public GeneratePassword() {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        retVal.toString().toUpperCase();
        return retVal;
    }
    public GenerateRandomString(length:number = 50) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    public CleanedString(cadena, spaceChar = '_') {
        var specialChars = "!@#$^&%*()+=-[]\/{}|:<>?,.";
        for (var i = 0; i < specialChars.length; i++) {
            cadena= cadena.replace(new RegExp("\\" + specialChars[i], 'gi'), '');
        }   
        cadena = cadena.toLowerCase();
        cadena = cadena.replace(/ /g,spaceChar);
        cadena = cadena.replace(/á/gi,"a");
        cadena = cadena.replace(/é/gi,"e");
        cadena = cadena.replace(/í/gi,"i");
        cadena = cadena.replace(/ó/gi,"o");
        cadena = cadena.replace(/ú/gi,"u");
        cadena = cadena.replace(/ñ/gi,"n");
        cadena = cadena.replace(/º/gi,"");
        cadena = cadena.replace(/ª/gi,"");
        return cadena;
    }
    public FileInput(accept:string = null, callback:any = null, error:any = null) : HTMLInputElement {
		var input = document.createElement('input');
		input.type = 'file';
		if (accept != null) input.accept = accept;
		input.onchange = event => {
            // if (accept != 'image/*') {
            // }
            if (input.files[0].size / 1024 / 1024 > 8) {
                input.value = '';
                swal('Tamaño excedido!', 'El tamaño para los archivos seleccionados debe ser inferior a 8 MB', 'warning');
                if (error != null) error();
                return;
            }
			let image = URL.createObjectURL(input.files[0]);
            event['imagen'] = image;
            if (callback != null) callback(event);
		}
		return input;
    }
    public Base64Image(input:HTMLInputElement, callback:any, maxsize:number = 2560) : void {
		if (input == null) return;
		if (callback == null) return;
		if (input.files.length == 0) {
            callback(null);
            return;
        }
        
        var calculateAspectRatioFit = function(srcWidth, srcHeight, maxWidth, maxHeight) {
            var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
            return {
                ratio: ratio,
                width: srcWidth * ratio,
                height: srcHeight * ratio
            };
        };

		var FR = new FileReader();
		FR.onload = e => {
            let canvas = document.createElement("canvas");
            var context = canvas.getContext("2d");
            let randstring = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            let img = $("<img id='temp-img-base64-"+randstring+"'>");
            img.bind('load', function() {
                var size = calculateAspectRatioFit(this.width, this.height, maxsize, maxsize);
                canvas.width = size.width;
                canvas.height = size.height;
                context.scale(size.ratio, size.ratio);
                context.drawImage(this, 0, 0);
                // console.log(canvas.toDataURL());
                callback(canvas.toDataURL('image/jpeg', .85));    
            });
            img.attr("src", FR.result);
		    //callback(FR.result);
		};
		FR.readAsDataURL(input.files[0]);
    }
    public Base64(input:HTMLInputElement, callback:any) : void {
		if (input == null) return;
		if (callback == null) return;
		if (input.files.length == 0) {
            callback(null);
            return;
        }
		var FR = new FileReader();
		FR.onload = e => {
		    callback(FR.result);
		};
		FR.readAsDataURL(input.files[0]);
    }
    public GridOrderJS(callback:any = null) {
        $(document).ready(() => {
            $('th[order]').each(function() {
                let th = $(this);
                th.addClass('cursor');
                th.html(`<div style="white-space:nowrap;">${th.html()} <i style="opacity:0.4" class="fa fa-chevron-up"></i></div>`);
                th[0].onclick = function() {
                    $('th[order!="'+$(this).attr('order')+'"]').each(function() {
                        $(this).attr('dir', 'asc');
                        if ($(this).find('i').length > 0) $(this).find('i')[0].className = 'fa fa-chevron-up';
                    });
            
                    let th = $(this);
                    if (th.attr('dir') == null || th.attr('dir') == 'asc') {th.attr('dir', 'desc');th.find('i')[0].className = 'fa fa-chevron-down';}
                    else {th.attr('dir', 'asc');th.find('i')[0].className = 'fa fa-chevron-up';}
                    let order = th.attr('order');
                    let dir = th.attr('dir');
                    if (callback) callback(order, dir);
                };
            });
        });
    }
    public CopyClipboard(str:string) {
      var el = document.createElement('textarea');
      el.value = str;
      el.setAttribute('readonly', '');
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    }
    public GetQueryString(name:string) {
        let url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
    public SaveData(object:any, key:string = window.location.pathname) {
        localStorage.setItem(key, JSON.stringify(object));
    }
    public GetData(key:string = window.location.pathname) : any {
        let data = localStorage.getItem(key);
        if (data) data = JSON.parse(data);
        return data;
    }
}